import styled from 'styled-components';
import {primaryColor} from '../../themes/colors';
export const Container = styled.div`
    // overflow-y:scroll;
    .top{
        display:flex;
        justify-content:space-between;
        margin-bottom:30px;
        .add-new-post{
            span{
                cursor:pointer;
                float:right;
                padding:5px 10px;
                background:${primaryColor};
                border-radius:4px;
                color:#fff;
                i{
                    padding-left:5px;
                }
            }
        }
    }
    .navigate{
        width:100px;
        display:flex;
        justify-content:space-between;
        padding-left:20px;
        p{
            cursor:pointer;
        }
    }
`;

export const Wrapper = styled.div`
    // overflow-y:scroll;
    
    table{
        width:100%;
        tr{
            th{
                text-align:justify;
                padding:15px;
                width:10%;
                :nth-child(){
                    width:20%;
                }
                // :nth-child(2){
                //     width:50%;
                // }
                // :nth-child(3){
                //     width:10%;
                // }
            }
        }
        tr{
            td{
                font-family:montserrat;
                font-size:14px;
                text-align:justify;
                padding:15px;
                .pointer{
                    cursor:pointer;
                }
                .status{
                    padding:5px 10px;
                    border-radius:4px;
                    text-align:center;
                }
                .cancelled{
                    background:red;
                    color:#fff;
                }
                .success{
                    background:green;
                    color:#fff;
                }
                .pending{
                    background:yellow;
                    color:#000;
                }
                // :nth-child(1){
                //     width:5%;
                // }
                // :nth-child(2){
                //     width:50%;
                // }
                :nth-child(){
                    width:20%;
                }
                // :nth-child(8),:nth-child(9){
                //    i{
                //         cursor:pointer;
                //         text-align:center;
                //    }
                // }
                
            }
        }
    }
`;
