import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Wrapper } from "./style";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Wrapper>
      <Header />
      <section className="intro-single">
        <div className="container">
          <h1>Terms and Conditions</h1>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="mb-5 pb-2">
            <h5 className="mb-3">Reservation Policy:</h5>
            <ul className="terms-general-ul">
              <li className="terms-general-li mb-1">
                Your order is secured upon receipt of a 70% payment deposit.
              </li>
              <li className="terms-general-li mb-1">
                Full payment inclusive of “refundable deposit” is required to be
                paid 72hours before event date.
              </li>
              <li className="terms-general-li mb-1">
                No order will be delivered without the receipt of 100% full
                payment.
              </li>
              <li className="terms-general-li mb-1">
                Order(s) is/are for one day hire. Kindly notify the sales
                department if order(s)will exceed one day for necessary invoice
                adjustment.
              </li>
              <li className="terms-general-li mb-1">
                All payments should be made only to the account details stated
                on our invoice, we will not be responsible for any payment made
                to any other account other than ours.
              </li>
            </ul>
          </div>

          <div className="mb-5 pb-2">
            <h5 className="mb-3">Postponement/Cancelation policy:</h5>
            <p>In the event of any cancelation or postponement,</p>
            <ul className="terms-general-ul">
              <li className="terms-general-li mb-1">
                Notice received less than 1 week to event date will attract a
                50% charge on rental order, the balance shall be moved to a new
                date or banked for a future event.
              </li>
              <li className="terms-general-li mb-1">
                Notice received 2 – 4 weeks to the event date will attract a 25%
                charge on rental order, the balance shall be moved to a new date
                or banked for a future event.
              </li>
              <li className="terms-general-li mb-1">
                Notice received 1 month in advance to the event date will not
                attract any rental order charge on payment deposit, the 100%
                payment deposit shall be moved to a new date or banked for a
                future event.
              </li>
              <p className="mt-2">
                <strong>
                  Please Note: We do not refund payment(s) received for a
                  confirmed order.
                </strong>
              </p>
            </ul>
          </div>

          <div className="mb-5 pb-2">
            <h5 className="mb-3">Delivery/Setup and Retrieval Policy:</h5>
            <ul className="terms-general-ul">
              <li className="terms-general-li mb-1">
                Items will be delivered the Night before your event date except
                if a different delivery time is agreed with the client upon
                confirmation of booking.
              </li>
              <li className="terms-general-li mb-1">
                Items shall be retrieved immediately event and reconfirmed a day
                to your event date except if stated otherwise by the client.
              </li>
              <li className="terms-general-li mb-1">
                For our setup, we will only accommodate a 45mins delay in a
                situation where the space is not ready. We will charge
                additional transportation fee, if we have to come back for
                setup.
              </li>
              <li className="terms-general-li mb-1">
                Payments for items delivered and unused will not be refunded.
              </li>
              <li className="terms-general-li mb-1">
                All deliveries require signature of the client or representative
                to confirm the quality and quantity of items delivered. Where
                the client is unavailable or refuse to sign our delivery form,
                our staff will take a video evidence and note it on the delivery
                form.
              </li>
              <li className="terms-general-li mb-1">
                We will only setup items supplied by our company and we will not
                be responsible for items supplied by another vendor.
              </li>
              <li className="terms-general-li mb-1">
                Items are to be handled with care. In the event of loss or
                damage of our item(s), we will retain your refundable deposit
                and issue additional invoice to cover the cost of loss or
                damage.
              </li>
            </ul>
          </div>

          <div className="mb-5 pb-2">
            <h5 className="mb-3">Power Supply:</h5>
            <ul className="terms-general-ul">
              <li className="terms-general-li mb-1">
                Client shall be responsible for the provision of power and a
                dedicated power source at the venue for all items that require
                power to function e.g Mist fans, Acs, Air coolers e.t.c
              </li>
              <li className="terms-general-li mb-1">
                Power supply source must be within a maximum distance of 10
                meters from the item(s) setup location.
              </li>
              <li className="terms-general-li mb-1">
                We will not operate existing electrical connections and fixtures
                at the venue. We advise you have a facility technician on
                standby.
              </li>
              <li className="terms-general-li mb-1">
                We will not be responsible for power surge or overloading of the
                power source; in the event the client will be using a generating
                set, we advise it’s in very good condition and the right
                capacity required.
              </li>
              <li className="terms-general-li mb-1">
                In a situation where there is no power to setup our items, we
                will pack up the items and leave.
              </li>
            </ul>
          </div>

          <div className="mb-5 pb-2">
            <h5 className="mb-3">Tents and Marquees.</h5>
            <p>
              In the case of sudden change in weather, such as strong wind,
              flood or any other unforeseen circumstances, immediately evacuate
              people or properties under the tent to avoid injuries or damages.
              Kindly inform the office immediately.
            </p>

            <p>
              Please do not use pins or sharp objects on our tents/Marquees.
              When draping around the tent, please drape without attaching
              fabrics to our tent canvas. We will charge for damages if we
              discover holes on our tents/Marquees.
            </p>
          </div>

          <div className="mb-5 pb-2">
            <h5 className="mb-3">Out of Lagos Events:</h5>
            <p>
              For events outside Lagos state, we will charge according to the
              number of days the items will be out for. This is because items
              will be unavailable for at least 48hrs.
            </p>
            <p>
              Client will be responsible for the safety of our staff and items
              at these out of Lagos locations.
            </p>
            <p>We do not tolerate and verbal or physical abuse of our staff.</p>
          </div>
        </div>
      </section>
      <Footer />
    </Wrapper>
  );
}
