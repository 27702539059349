import React, { Component } from "react";
import * as UI from "./style";
import { Link, Redirect } from "react-router-dom";
import { Input1 } from "../../components/Inputs";
import axios from "axios";

export default class Login extends Component {
  state = {
    email: "",
    password: "",
    msg: "",
    loading: false,
  };

  componentDidMount() {
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params1)
      .then((res) => {
        if (res.data.user !== null) {
          // return this.props.prop.history.push('/login')
          console.log(res.data.user);
          return (window.location = "/dashboard");
        }
      })
      .catch((e) => console.log(e));
  }

  handleInput = (e) => {
    switch (e.target.name) {
      case "email":
        this.setState({
          email: e.target.value,
        });
        break;

      case "password":
        this.setState({
          password: e.target.value,
        });
        break;
    }
  };
  submitForm = (e) => {
    const { email, password } = this.state;
    if (email === "" || password === "") {
      return this.setState({
        msg: "Fields cannot be Empty!",
      });
    }

    const params = {
      email: email,
      password: password,
    };
    this.setState({
      loading: true,
    });
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/login", params)
      .then((res) => {
        this.setState({
          msg: res.data.message,
          loading: false,
        });
        if (res.data.auth) {
          localStorage.setItem("auth", res.data.token);
          this.props.history.push("/dashboard");
        }
        console.log(res);
      })
      .catch((e) => console.log(e));
  };
  render() {
    return (
      <UI.Container>
        {/* Login */}
        <div className="wrapper">
          <UI.Left>
            <div
              style={{
                background: "url(../img/gallery/banner.jpg)",
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </UI.Left>

          <UI.Right>
            <div className="right-container">
              <div className="logo">
                <Link to="/">
                  <img src="../img/tprs.png" alt="tprs" />
                </Link>
              </div>

              <form>
                {this.state.msg !== "" ? (
                  <div className="text-danger">{this.state.msg}</div>
                ) : null}
                <Input1
                  type="email"
                  value={this.state.email}
                  placeholder="Email Address"
                  icon="fa fa-envelope"
                  name="email"
                  handleInput={(e) => this.handleInput(e)}
                />

                <Input1
                  type="password"
                  value={this.state.password}
                  placeholder="Password"
                  icon="fa fa-key"
                  name="password"
                  handleInput={(e) => this.handleInput(e)}
                />

                <div className="form-btn">
                  <span onClick={() => this.submitForm()}>
                    {this.state.loading ? (
                      <img src={require("../../assets/img/loader.gif")} />
                    ) : (
                      "Login"
                    )}
                  </span>
                </div>
                <p className="text">
                  Don't have an account yet?{" "}
                  <Link to="/register">Register</Link>
                </p>
              </form>
            </div>
          </UI.Right>
        </div>
      </UI.Container>
    );
  }
}
