import React from "react";
import clsx from "classnames";
import styled from "styled-components";

const Wrapper = styled.div`
  overflow: hidden;
  border-bottom: 1px solid #bdbdbd;
  padding: 20px 0;

  .faq-question {
    cursor: pointer;
    margin-bottom: 0;
  }

  .faq-answer {
    height: 0;
    overflow: hidden; /* Hide excess content */
    margin: 0;
    transition: all 0.4s ease;
    font-size: 15px;
  }

  &.active {
    .faq-answer {
      height: 100%;
      padding-top: 10px;
    }
  }
`;

export default function FaqItem({ question, answer, active, onClick }) {
  const handleOnClick = () => {
    if (active === question) {
      onClick(null);
    } else {
      onClick(question);
    }
  };

  return (
    <Wrapper
      className={clsx({
        active: active === question,
      })}
    >
      <h6 className="faq-question" onClick={handleOnClick}>
        {question}
      </h6>
      <p className="faq-answer">{answer}</p>
    </Wrapper>
  );
}
