import React, { Component } from "react";
import { socials } from "../../utils/social";
import * as UI from "./style";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default class Contact extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <UI.Container>
        <Header />
        <section class="intro-single">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-8">
                <div class="title-single-box">
                  <h1 class="title-single">Contact US</h1>
                  <span class="color-text-a">
                    Feel free to contact us via any of the following ways.
                  </span>
                </div>
              </div>
              <div class="col-md-12 col-lg-4">
                <nav
                  aria-label="breadcrumb"
                  class="breadcrumb-box d-flex justify-content-lg-end"
                >
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Contact
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section class="contact">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="contact-map box">
                  <div id="map" class="contact-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.058575587862!2d3.32693138098124!3d6.514270506475462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d8ff13a0ae9%3A0x9a03132f62d0e299!2sThe%20Party%20Rental%20Station(TPRS)!5e0!3m2!1sen!2sng!4v1731025476000!5m2!1sen!2sng"
                      width="100%"
                      height="450"
                      frameborder="0"
                      style={{ border: "0px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 section-t8">
                <div class="section-md-t3 row">
                  <div class="icon-box section-b2 col-lg-5">
                    <div class="icon-box-icon">
                      <span class="ion-ios-paper-plane"></span>
                    </div>
                    <div class="icon-box-content table-cell">
                      <div class="icon-box-title">
                        <h4 class="icon-title">Say Hello</h4>
                      </div>
                      <div class="icon-box-content">
                        <p
                          class="mb-3 d-flex  align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <span> Email. </span>
                          <div>
                            <a
                              href="mailto:thepartyrentalstation@gmail.com"
                              class="color-a d-block rdx-textx"
                            >
                              thepartyrentalstation@gmail.com
                            </a>
                            <a
                              href="mailto:info@thepartyrentalstation.com"
                              class="color-a d-block  rdx-textx"
                            >
                              info@thepartyrentalstation.com
                            </a>
                          </div>
                        </p>
                        <p
                          class="mb-1 d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <span> Phone. </span>
                          <div>
                            <div className="d-md-flex" style={{ gap: "5px" }}>
                              <a
                                href="tel:+2349051135384,"
                                class="color-a d-block"
                              >
                                +234 905 113 5384,
                              </a>
                              <a
                                href="tel:+2348186212894"
                                class="color-a d-block"
                              >
                                +234 818 621 2894
                              </a>
                            </div>
                            <div className="d-md-flex" style={{ gap: "5px" }}>
                              <a
                                href="tel:+2348185876815"
                                class="color-a d-block"
                              >
                                +234 818 587 6815,
                              </a>
                              <a
                                href="tel:+2348115565187"
                                class="color-a d-block"
                              >
                                +234 811 556 5187
                              </a>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="icon-box section-b2 col-lg-4 col-md-6">
                    <div class="icon-box-icon">
                      <span class="ion-ios-pin"></span>
                    </div>
                    <div class="icon-box-content table-cell">
                      <div class="icon-box-title">
                        <h4 class="icon-title">Find us in</h4>
                      </div>
                      <div class="icon-box-content">
                        <p class="mb-1">
                          22 Apapa Oshodi Express by Berliet Bustop, Ilasa,
                          Lagos.
                        </p>
                      </div>

                      <div class="icon-box-content">
                        <h6>Opening Hours</h6>
                        <p class="mb-1">Monday to Friday: 8am -6pm</p>
                        <p class="mb-1">Saturday: 11am to 4pm</p>
                        <p class="mb-1">Sunday: Closed.</p>
                      </div>
                    </div>
                  </div>
                  <div class="icon-box col-lg-3 col-md-6">
                    <div class="icon-box-icon">
                      <span class="ion-ios-redo"></span>
                    </div>
                    <div class="icon-box-content table-cell">
                      <div class="icon-box-title">
                        <h4 class="icon-title">Social networks</h4>
                      </div>
                      <div class="icon-box-content">
                        <div class="socials-footer">
                          <ul class="list-inline">
                            {socials.map((item) => (
                              <li class="list-inline-item" key={item.link}>
                                <a
                                  href={item.link}
                                  class="link-one"
                                  target="blank"
                                >
                                  {item.icon}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </UI.Container>
    );
  }
}
