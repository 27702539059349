import React from "react";

const RentalItem = ({ img, name, type, link }) => {
  return (
    <div class="card-box-a card-shadow">
      <div class="img-box-a">
        <img src={img} alt="" class="img-a img-fluid my-img-2" />
      </div>
      <div class="card-overlay">
        <div class="card-overlay-a-content">
          <div class="card-header-a">
            <h2 class="card-title-a">
              <a href={link}>
                <br />
                {name}{" "}
              </a>
            </h2>
          </div>

          <div class="card-footer-a">
            <ul class="card-info">
              <a href={link}>
                <li>
                  <span style={{ opacity: "1" }}>Visit section</span>
                </li>
              </a>

              <li>
                <h4 class="card-info-title">{type}</h4>
                {/* <!-- <span>2</span> --> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalItem;
