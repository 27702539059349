import React, { Component } from "react";
import * as UI from "./style";
import Menu from "../Menu";
import Box1 from "../Box-1";
import { Link } from "react-router-dom";
import Header from "../Header";
import axios from "axios";

class AdminDashboardEssentials extends Component {
  state = {
    user: "",
  };

  componentDidMount(props) {
    const auth = localStorage.getItem("auth");
    if (!auth || auth === "") {
      // console.log(this.props.location)
      this.props.prop.history.push("/login");
    } else {
      const auth = localStorage.getItem("auth");
      const params = {
        token: auth,
      };
      axios
        .post(
          "https://sheltered-cliffs-11423.herokuapp.com/api/admin/auth",
          params
        )
        .then((res) => {
          if (res.data.user === null) {
            return this.props.prop.history.push("/");
          }
          // this.setState({
          //     user:res.data.user
          // })
        });
    }
    // else{
    //     re
    // }
  }
  render() {
    return (
      <div>
        {/* <UI.Header>
                    <div className="logo-container">
                        <div className="logo">
                            <img src={require('../../assets/img/logo.svg')} alt="glint football"/>
                            <Link to="/" style={{color:'white',textDecoration:"none"}}>
                                <h1>Glint Football</h1>
                            </Link>
                        </div>
                    </div>
                </UI.Header> */}
        <Header />

        <UI.Content>
          <UI.SideNav>
            <Menu name="Dashboard" link="/admin/dashboard" icon="dashboard" />
            <Menu
              name="Cake tables"
              link="/admin/products/cake-tables"
              icon="dashboard"
            />
            <Menu
              name="Centerpiece and pedestal"
              link="/admin/products/centerpiece-pedestal"
              icon="dashboard"
            />
            <Menu
              name="Crockeries"
              link="/admin/products/crockeries"
              icon="dashboard"
            />
            <Menu
              name="Flowers"
              link="/admin/products/flowers"
              icon="dashboard"
            />
            <Menu
              name="Flooring &amp; carpets"
              link="/admin/products/flooring-carpets"
              icon="dashboard"
            />
            <Menu
              name="Kiddies's Collection"
              link="/admin/products/kiddies"
              icon="dashboard"
            />
            <Menu
              name="Lightening and SE"
              link="/admin/products/lightening"
              icon="dashboard"
            />
            <Menu
              name="Linen Collection"
              link="/admin/products/linen"
              icon="dashboard"
            />
            <Menu
              name="Mobile tents &amp; Canopies"
              link="/admin/products/tent-canopies"
              icon="dashboard"
            />
            <Menu
              name="Rustic Furniture &amp; props"
              link="/admin/products/furniture-props"
              icon="dashboard"
            />
            <Menu
              name="Speciality Furniture"
              link="/admin/products/speciality-furniture"
              icon="dashboard"
            />
            <Menu
              name="Upholster Furniture"
              link="/admin/products/upholster-furniture"
              icon="dashboard"
            />
            <Menu
              name="Wedding Chairs &amp; Table"
              link="/admin/products/wedding-chairs-table"
              icon="dashboard"
            />
            <Menu
              name="Others"
              link="/admin/products/others"
              icon="dashboard"
            />
            {/* <Menu name="Upholster Furniture" link="" icon="dashboard"/>
                        <Menu name="Wedding Chairs &amp; Table" link="" icon="dashboard"/>
                        <Menu name="Others" link="" icon="dashboard"/> */}
          </UI.SideNav>

          <UI.Body>
            <div className="user">
              <span className="online"></span>Hello{" "}
              <h6 style={{ display: "inline-block" }}>ADMIN</h6>{" "}
              {this.state.user.name}
            </div>
            {this.props.children}
          </UI.Body>
        </UI.Content>
      </div>
    );
  }
}

export default AdminDashboardEssentials;
