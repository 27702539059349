/* eslint-disable react/react-in-jsx-scope */
import React from "react";

import ThreadSvg from "../assets/img/threads.svg";
import TikTokSvg from "../assets/img/tiktok.svg";

export const socials = [
  {
    icon: <i class="fa fa-facebook" aria-hidden="true"></i>,
    link: "https://facebook.com/thepartyrentalstation",
    text: "",
  },
  {
    icon: <i class="fa fa-linkedin" aria-hidden="true"></i>,
    link: "https://www.linkedin.com/in/the-party-rental-station-tprs-8109b91a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    text: "",
  },
  {
    icon: <i class="fa fa-instagram" aria-hidden="true"></i>,
    link: "https://instagram.com/thepartyrentalstation",
    text: "",
  },
  {
    icon: <img src={TikTokSvg} alt="threads" style={{ width: "13px" }} />,
    link: "https://www.tiktok.com/@tprs1",
    text: "",
  },
  {
    icon: <img src={ThreadSvg} alt="threads" style={{ width: "13px" }} />,
    link: "https://www.threads.net/@thepartyrentalstation?hl=en",
    text: "",
  },
];
