import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled.div`
  margin-top: 130px;

  .slick-arrow {
    display: none !important;
  }

  .slider-item-1 {
    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.5),
        rgba(139, 0, 139, 0.4)
      ),
      url(img/new-batch1-14.jpg);
  }

  .slider-item-2 {
    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.7),
        rgba(139, 0, 139, 0.4)
      ),
      url(img/new-batch1-22.jpg);
  }
  .slider-item-3 {
    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.7),
        rgba(139, 0, 139, 0.4)
      ),
      url(img/new-batch1-11.jpg);
  }

  .slider-item {
    height: calc(-97px + 100vh);
    position: relative;
    background-size: cover;
    background-position: center;

    .text {
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      z-index: 1;

      h3 {
        color: rgb(255, 255, 255);
        font-size: 50px;
        width: 50%;
      }

      p {
        color: rgb(255, 255, 255);
        font-size: 25px;
        width: 65%;
      }
    }

    .center-it {
      //   height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    .slider-item {
      .text {
        h3 {
          width: 80%;
        }

        p {
          width: 90%;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .slider-item {
      .text {
        h3 {
          font-size: 32px;
          width: 95%;
        }

        p {
          font-size: 18px;
          width: 95%;
        }
      }
    }
  }

  @media (max-width: 450px) {
    margin-top: 97px;
  }
`;

const Banner = () => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    fade: true,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        <div className="slider-item slider-item-1">
          <div class="container">
            <div class="text">
              <div class="center-it">
                <h3>THE PARTY RENTAL STATION</h3>
                <p>
                  A luxury rental company offering a unique line of stylish
                  decor items and event solutions for various types of event.
                </p>
                <button
                  type="button"
                  class="whatsapp-dm-btn"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send/?phone=%2B2348186212894&text=Chairman%2C+I+would+like+to+rent",
                      "_blank"
                    )
                  }
                >
                  Send Us a DM on Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item slider-item-2"
          style={{ backgroundImage: `url(img/gallery/gallery3.jpg)` }}
        >
          <div class="container">
            <div class="text">
              <div class="center-it">
                <h3>LUXURY EVENT ITEMS</h3>
                <p>
                  We specialize on the unique details of your event to make your
                  occasion as special as possible from the theme of your decor
                  to the luxury furniture and silverware on your table.
                </p>
                <button
                  type="button"
                  class="whatsapp-dm-btn"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send/?phone=%2B2348186212894&text=Chairman%2C+I+would+like+to+rent",
                      "_blank"
                    )
                  }
                >
                  Send Us a DM on Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item slider-item-3"
          style={{ backgroundImage: `url(img/gallery/gallery3.jpg)` }}
        >
          <div class="container">
            <div class="text">
              <div class="center-it">
                <h3>HOME OF QUALITY </h3>
                <p>
                  The Party Rental Station prides herself in offering the most
                  unique products in the rental market as well as exceptional
                  customer service.
                </p>
                <button
                  type="button"
                  class="whatsapp-dm-btn"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send/?phone=%2B2348186212894&text=Chairman%2C+I+would+like+to+rent",
                      "_blank"
                    )
                  }
                >
                  Send Us a DM on Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </Wrapper>
  );
};

export default Banner;
