import React, { Component } from "react";
import * as UI from "./style";
import Box1 from "../../components/Box-1";
import { Link } from "react-router-dom";
import DashboardEssentials from "../../components/Dashboard-Essentials";
import AddPost from "../../components/AddPost";
import SureDelete from "../../components/SureDelete";
import axios from "axios";
export default class DashboardNewsPost extends Component {
  state = {
    addPost: false,
    confirmAction: false,
    orders: "",
    skip: 0,
    products: "",
    refresh: false,
  };

  componentDidMount() {
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };
    let params = "";
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params1)
      .then((res) => {
        if (res.data.user === null) {
          return this.props.history.push("/login");
        }
        this.setState({
          user: res.data.user,
        });
        params = {
          id: res.data.user._id,
        };
        axios
          .post(
            `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=6&order=desc`,
            params
          )
          .then((res) => {
            // console.log(res)
            this.setState({
              orders: res.data.doc,
            });
          })
          .catch((e) => console.log(e));

        axios
          .post(
            `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc`,
            params
          )
          .then((res) => {
            // console.log(res)
            this.setState({
              orderNo: res.data.doc.length,
            });
          });
      });
    this.getProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.skip !== this.state.skip) {
      const params = {
        id: this.state.user._id,
      };
      axios
        .post(
          `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=6&order=desc`,
          params
        )
        .then((res) => {
          console.log(res);
          this.setState({
            orders: res.data.doc,
          });
        })
        .catch((e) => console.log(e));
    }

    if (prevState.refresh !== this.state.refresh) {
      const params = {
        id: this.state.user._id,
      };
      axios
        .post(
          `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=6&order=desc`,
          params
        )
        .then((res) => {
          console.log(res);
          this.setState({
            orders: res.data.doc,
          });
        })
        .catch((e) => console.log(e));
      console.log("Yes");
    }
  }
  toggleAddPost = (value) => {
    this.setState({
      addPost: value,
    });
  };

  toggleDeletePost = (id, value) => {
    this.setState({
      confirmAction: value,
      updateOrderId: id,
    });
  };

  getProducts = () => {
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=0&order=asc`
      )
      .then((res) => {
        this.setState({
          products: res.data,
        });
        // console.log(res.data)
        // return res.data.doc
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getProductName = (id) => {
    // console.log(id)
    let data = this.state.products.find((item) => {
      return item._id === id;
    });
    if (data) {
      return data.name;
    }
  };

  renderIndividualOrders = (items) => {
    // console.log(this.state.productName)
    return items.map((item, i) => {
      //  this.getItemName(item.id)
      return (
        <li>
          <span className="item-name">
            <h5>Name:</h5> <p>{this.getProductName(item.id)}</p>
          </span>

          <span className="item-quantity">
            <h5>Quantity:</h5> <p>{item.quantity}</p>
          </span>
        </li>
      );
    });
  };

  getNumbers = () => {
    this.setState({
      orderNo: this.state.orderNo - 1,
    });
  };
  renderOrders = () => {
    const orders = this.state.orders;
    // console.log(this.state.orderNo)
    // let number = this.state.orderNo
    return orders
      ? orders.map((item, i) => {
          // for(let i = this.state.orderNo; i > 0; --i){
          //     --number
          // }
          return (
            <tr key={i}>
              <td>{item._id}</td>
              <td>{this.renderIndividualOrders(item.items)}</td>
              <td>{this.renderStatus(item.status)}</td>
              <td>{item.createdAt}</td>
              <td>
                {item.status === "cancelled" ? null : (
                  <i
                    className="fa fa-times pointer"
                    onClick={() => this.toggleDeletePost(item._id, true)}
                  ></i>
                )}
              </td>
            </tr>
          );
        })
      : null;
  };

  updateStatus = (status) => {
    const params = {
      _id: this.state.updateOrderId,
      status: status,
    };
    // console.log(params)
    axios
      .post(
        "https://sheltered-cliffs-11423.herokuapp.com/api/order/update",
        params
      )
      .then((res) => {
        console.log(res);
        this.setState({
          confirmAction: false,
          refresh: true,
        });
      });
  };

  nextPrev = (type) => {
    if (type === "next") {
      this.setState({
        skip: this.state.skip + 6,
      });
    } else {
      this.setState({
        skip: this.state.skip - 6,
      });
    }
  };
  renderStatus = (item) => {
    let template = "";
    switch (item) {
      case "accepted":
        template = <p className="status success"> {item}</p>;
        break;
      case "cancelled":
        template = <p className="status cancelled"> {item}</p>;

      case "rejected":
        template = <p className="status cancelled"> {item}</p>;
        break;
      case "pending":
        template = <p className="status pending"> {item}</p>;
        break;
      default:
        template = <p className="status pending"> {item}</p>;
    }
    return template;
  };

  render() {
    return (
      <UI.Container>
        <AddPost
          status={this.state.addPost}
          hide={() => this.toggleAddPost()}
        />
        <SureDelete
          status={this.state.confirmAction}
          hide={() => this.toggleDeletePost()}
          text="Are you sure you want to cancel this order?"
          proceed={() => this.updateStatus("cancelled")}
        />
        <DashboardEssentials prop={this.props}>
          <div className="container">
            <div className="top">
              <h2>Orders</h2>
              {/* <div className="add-new-post">
                                <span onClick={() => this.toggleAddPost(true)}>Add new post <i className="fa fa-plus"></i></span>
                            </div> */}
            </div>

            <UI.Wrapper>
              <div className="posts">
                <div className="table table-bordered">
                  <table>
                    <tbody>
                      <tr>
                        <th>Order Id.</th>
                        <th>Items</th>
                        <th>Status</th>
                        <td>Date Ordered </td>
                        {/* <th>Image 1</th>
                                                <th>Image 2</th>
                                                <th>tag</th>
                                                <th>Author</th>
                                                <th>Edit</th> */}
                        <th>Cancel</th>
                      </tr>
                      {this.renderOrders()}
                    </tbody>
                  </table>
                </div>
              </div>
            </UI.Wrapper>
          </div>

          <div className="navigate">
            <p onClick={() => this.nextPrev("next")}>Next</p>
            <p onClick={() => this.nextPrev("prev")}>Prev</p>
          </div>
        </DashboardEssentials>
      </UI.Container>
    );
  }
}

// image1
// image2
// tag
// title
// desc
// author
