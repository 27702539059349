import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Layout from "./hoc/layout";
import Index from "./pages/index";
import About from "./pages/about";
import Login from "./pages/login";
import AdminLogin from "./pages/Admin-Login";
import Register from "./pages/register";
import Contact from "./pages/contact";
import Product from "./pages/product";
import DashboardHome from "./pages/Dashboard-Home";
import AdminDashboardHome from "./pages/Admin-Dashboard-Home";
import DashboardOrder from "./pages/Dashboard-Order";
import AdminDashboardProduct from "./pages/Admin-Dashboard-Product";
import AdminDashboardOrder from "./pages/Admin-Dashboard-Order";
import TermsAndConditions from "./pages/terms-and-condition";
import Faqs from "./pages/faqs";
import Projects from "./pages/projects";

const Routes = (props) => {
  return (
    <Layout>
      <BrowserRouter>
        <Switch>
          <Route
            path="/admin/products/:product"
            exact
            component={AdminDashboardProduct}
          />
          <Route
            path="/admin/orders/:order"
            exact
            component={AdminDashboardOrder}
          />
          <Route path="/admin/dashboard" exact component={AdminDashboardHome} />
          <Route path="/admin/login" exact component={AdminLogin} />
          <Route path="/dashboard" exact component={DashboardHome} />
          <Route path="/orders" exact component={DashboardOrder} />
          <Route path="/register" exact component={Register} />

          <Route path="/login" exact component={Login} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/about" exact component={About} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/faqs" exact component={Faqs} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsAndConditions}
          />
          <Route path="/item/:product" component={Product} />
          <Route path="/" component={Index} />
          {/* <PrivateRoute path="/protected" component={Protected} /> */}
        </Switch>
      </BrowserRouter>
    </Layout>
  );
};

export default Routes;
