import React, { Component } from "react";
import * as UI from "./style";
import { Link } from "react-router-dom";
import { Input1 } from "../../components/Inputs";
import axios from "axios";

export default class Register extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    password: "",
    cPassword: "",
    msg: "",
  };

  componentDidMount() {
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params1)
      .then((res) => {
        if (res.data.user !== null) {
          // return this.props.prop.history.push('/login')
          console.log(res.data.user);
          return (window.location = "/dashboard");
        }
      })
      .catch((e) => console.log(e));
  }

  handleInput = (e) => {
    switch (e.target.name) {
      case "name":
        this.setState({
          name: e.target.value,
        });
        break;

      case "email":
        this.setState({
          email: e.target.value,
        });
        break;

      case "phone":
        this.setState({
          phone: e.target.value,
        });
        break;

      case "password":
        this.setState({
          password: e.target.value,
        });
        break;

      case "cPassword":
        this.setState({
          cPassword: e.target.value,
        });
        break;
    }
  };

  submitForm = (e) => {
    const { name, email, phone, password, cPassword } = this.state;
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      password === "" ||
      cPassword === ""
    ) {
      this.setState({
        msg: "Fields cannot be Empty!",
      });
    }

    const params = {
      email: email,
      password: password,
      name: name,
      phone: phone,
    };

    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/register", params)
      .then((res) => {
        this.setState({
          msg: res.data.message,
        });
        if (res.data.doc) {
          setTimeout(() => {
            return this.props.history.push("/login");
          }, 1000);
        }

        console.log(res);
      })
      .catch((e) => console.log(e));
    // console.log(this.state.msg)
  };
  render() {
    return (
      <UI.Container>
        {/* Login */}
        <div className="wrapper">
          <UI.Left>
            <div
              style={{
                background: "url(../img/gallery/banner.jpg)",
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </UI.Left>

          <UI.Right>
            <div className="right-container">
              <div className="logo">
                <Link to="/">
                  <img src="../img/tprs.png" alt="tprs" />
                </Link>
              </div>
              {this.state.msg !== "" ? (
                <div className="error">{this.state.msg}</div>
              ) : null}
              <form>
                <Input1
                  type="text"
                  value={this.state.name}
                  placeholder="Full Name"
                  icon="fa fa-user"
                  name="name"
                  handleInput={(e) => this.handleInput(e)}
                />
                <Input1
                  type="email"
                  value={this.state.email}
                  placeholder="Email Address"
                  icon="fa fa-envelope"
                  name="email"
                  handleInput={(e) => this.handleInput(e)}
                />
                <Input1
                  type="text"
                  value={this.state.phone}
                  placeholder="Phone Number"
                  icon="fa fa-phone"
                  name="phone"
                  handleInput={(e) => this.handleInput(e)}
                />
                <Input1
                  type="password"
                  value={this.state.password}
                  placeholder="Password"
                  icon="fa fa-key"
                  name="password"
                  handleInput={(e) => this.handleInput(e)}
                />
                <Input1
                  type="password"
                  value={this.state.cPassword}
                  placeholder="Confirm Password"
                  icon="fa fa-key"
                  name="cPassword"
                  handleInput={(e) => this.handleInput(e)}
                />

                <div className="form-btn">
                  <span onClick={() => this.submitForm()}>Sign up</span>
                </div>
                <p className="text">
                  Do you have an active account?{" "}
                  <Link to="/login" className="lg-btn">
                    Login
                  </Link>
                </p>
              </form>
            </div>
          </UI.Right>
        </div>
      </UI.Container>
    );
  }
}
