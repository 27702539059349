import React, { Component } from "react";
import * as UI from "./style";
import Box1 from "../../components/Box-1";
import { Link } from "react-router-dom";
import DashboardEssentials from "../../components/Dashboard-Essentials";
import axios from "axios";

export default class DashboardHome extends Component {
  state = {};
  componentDidMount() {
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };
    let params = "";
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params1)
      .then((res) => {
        if (res.data.user === null) {
          // return this.props.prop.history.push('/login')
          window.location = "/login";
        }
        this.setState({
          user: res.data.user,
        });
        params = {
          id: res.data.user._id,
        };
        axios
          .post(
            `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc`,
            params
          )
          .then((res) => {
            console.log(res);
            this.setState({
              orders: res.data.doc,
              orderNo: res.data.doc.length,
            });
          })
          .catch((e) => console.log(e));
      });
  }
  render() {
    return (
      <UI.Container>
        <DashboardEssentials prop={this.props}>
          <div className="container">
            <h2>Dashboard Home</h2>
            <div className="row">
              <div className="col-lg-3">
                <Box1
                  heading="Your Orders"
                  value={this.state.orderNo}
                  icon="truck"
                />
              </div>

              {/* <div className="col-lg-3">
                               <Box1/>
                           </div> */}
            </div>
          </div>
        </DashboardEssentials>
      </UI.Container>
    );
  }
}

// image1
// image2
// tag
// title
// desc
// author
