import React, { Component } from "react";
import * as UI from "./style";
import Box1 from "../../components/Box-1";
import { Link } from "react-router-dom";
import AdminDashboardEssentials from "../../components/Admin-Dashboard-Essentials";
import AddProduct from "../../components/AddPost";
import SureDelete from "../../components/SureDelete";
import axios from "axios";
export default class AdminDashboardProduct extends Component {
  state = {
    addProduct: false,
    deletePost: false,
    products: "",
    confirmAction: false,
    updateOrAdd: "",
    productUpdateId: "",
    skip: 0,
  };

  componentDidMount() {
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };

    axios
      .post(
        "https://sheltered-cliffs-11423.herokuapp.com/api/admin/auth",
        params1
      )
      .then((res) => {
        if (res.data.user === null) {
          return this.props.history.push("/login");
        }

        const category = this.selectCategory(this.props.match.params.product);
        axios
          .get(
            `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=${this.state.skip}&limit=10&category=${category}&order=desc`
          )
          .then((res) => {
            // console.log(res)
            this.setState({
              products: res.data,
            });
          })
          .catch((e) => console.log(e));
      });
  }

  selectCategory = (type) => {
    let category = "";
    switch (type) {
      case "cake-tables":
        category = "cake-tables";
        break;
      case "centerpiece-pedestal":
        category = "centerpiece-pedestal";
        break;
      case "crockeries":
        category = "crockeries";
        break;
      case "flowers":
        category = "flowers";
        break;
      case "flooring-carpets":
        category = "flooring-carpets";
        break;
      case "kiddies":
        category = "kiddies";
        break;
      case "lightening":
        category = "lightening";
        break;
      case "linen":
        category = "linen";
        break;
      case "tent-canopies":
        category = "tent-canopies";
        break;
      case "furniture-props":
        category = "furniture-props";
        break;
      case "speciality-furniture":
        category = "speciality-furniture";
        break;
      case "upholster-furniture":
        category = "upholster-furniture";
        break;
      case "wedding-chairs-table":
        category = "wedding-chairs-table";
        break;
      case "others":
        category = "others";
        break;
      default:
        category = "";
    }
    return category;
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.product !== this.props.match.params.product) {
      const category = this.selectCategory(this.props.match.params.product);
      // console.log(category)
      axios
        .get(
          `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=${this.state.skip}&limit=10&category=${category}&order=desc`
        )
        .then((res) => {
          // console.log(res)
          this.setState({
            products: res.data,
          });
        })
        .catch((e) => console.log(e));
    }

    if (this.state.itemDeleteId === "deleted") {
      const category = this.selectCategory(this.props.match.params.product);
      // console.log(category)
      axios
        .get(
          `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=${this.state.skip}&limit=10&category=${category}&order=desc`
        )
        .then((res) => {
          // console.log(res)
          this.setState({
            products: res.data,
          });
        })
        .catch((e) => console.log(e));
    }
    if (prevState.skip !== this.state.skip) {
      console.log(prevState.skip, this.state.skip);
      const category = this.selectCategory(this.props.match.params.product);
      axios
        .get(
          `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=${this.state.skip}&limit=10&category=${category}&order=desc`
        )
        .then((res) => {
          console.log(res);
          this.setState({
            products: res.data,
          });
        })
        .catch((e) => console.log(e));
    }
  }
  toggleAddProduct = (value) => {
    this.setState({
      addProduct: value,
      updateOrAdd: "add",
    });
  };

  editItem = () => {
    console.log();
    return (
      <AddProduct
        status={this.state.addPost}
        hide={() => this.toggleAddPost()}
        category={this.props.match.params.product}
      />
    );
  };

  toggleDeleteItem = (value, id) => {
    this.setState({
      confirmAction: value,
      itemDeleteId: id,
    });
  };
  toggleEditProduct = (value, id) => {
    this.setState({
      addProduct: value,
      updateOrAdd: "update",
      productUpdateId: id,
    });
  };

  deleteItem = (id) => {
    // const params={
    //     _id:this.state.updateOrderId,
    //     status:status,
    //     read:true
    // }
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/product/delete?id=${id}`
      )
      .then((res) => {
        console.log(res);
        this.setState({
          confirmAction: false,
          itemDeleteId: "deleted",
        });
      });

    // window.location.reload()
  };
  // renderIndividualOrders = (item) =>{
  //     return item.map((item,i) =>{
  //         return <td>Id:{item.id} Quantity:{item.quantity}</td>
  //     })
  // }

  renderOrders = () => {
    const products = this.state.products;
    // console.log(product)
    return products
      ? products.map((item, i) => {
          return (
            <tr>
              <td>{i + 1}</td>
              <td>{item.name}</td>
              {/* <td>{item.description}</td> */}
              <td>
                <a target="blank" href={item.image}>
                  {item.image}
                </a>
              </td>
              <td>
                <i
                  className="fa fa-pencil pointer"
                  onClick={() => this.toggleEditProduct(true, item._id)}
                ></i>
              </td>
              <td>
                <i
                  className="fa fa-trash pointer"
                  onClick={() => this.toggleDeleteItem(true, item._id)}
                ></i>
              </td>
            </tr>
          );
        })
      : null;
  };

  navigateProduct = (action) => {
    if (action === "next") {
      this.setState({
        skip: this.state.skip + 10,
      });
    } else {
      this.setState({
        skip: this.state.skip + 10,
      });
    }
  };

  render() {
    return (
      <UI.Container>
        <AddProduct
          status={this.state.addProduct}
          hide={() => this.toggleAddProduct(false)}
          category={this.props.match.params.product}
          type={this.state.updateOrAdd}
          updateId={this.state.productUpdateId}
        />
        <SureDelete
          status={this.state.confirmAction}
          hide={() => this.toggleDeleteItem(false)}
          text="Are you sure you want to  delete this item?"
          proceed={() => this.deleteItem(this.state.itemDeleteId)}
        />
        {/* <SureDelete status={this.state.deletePost} hide={() => this.toggleDeletePost()}/> */}
        <AdminDashboardEssentials prop={this.props}>
          <div className="container">
            <div className="top">
              <h2>Products</h2>
              <div className="add-new-post">
                <span onClick={() => this.toggleAddProduct(true)}>
                  Add new Item <i className="fa fa-plus"></i>
                </span>
              </div>
            </div>

            <UI.Wrapper>
              <div className="posts">
                <div className="table table-bordered">
                  <table>
                    <tbody>
                      <tr>
                        <th>No.</th>
                        <th>Name</th>
                        {/* <th>Description</th> */}
                        <th>Image Link </th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                      {this.renderOrders()}
                    </tbody>
                  </table>
                </div>
              </div>
            </UI.Wrapper>
          </div>

          <div className="navigate">
            <p onClick={() => this.navigateProduct("next")}>Next</p>
            <p onClick={() => this.navigateProduct("prev")}>Prev</p>
          </div>
        </AdminDashboardEssentials>
      </UI.Container>
    );
  }
}

// image1
// image2
// tag
// title
// desc
// author
