import React, { Component } from "react";
import styled from "styled-components";
import Checkout from "../../components/Checkout";
import SuccessModal from "../../components/Success-Modal";
import "./layout.css";
import "../../assets/css/style.css";

class Layout extends Component {
  state = {
    checkout: false,
    cartItem: "",
    updateCart: false,
    successModal: false,
  };

  componentDidUpdate(prevProps) {
    // if(prevProps.)
    // this.updateCart()
  }
  updateCart = () => {
    this.setState({
      cartItem: JSON.parse(localStorage.getItem("cart-items")).length,
    });
  };
  wrapper = styled.div`
    // font-family: 'Roboto', sans-serif;
    // font-weight:700;
    // padding:0 100px;
  `;

  toggleCheckout = () => {
    this.setState({
      checkout: !this.state.checkout,
    });
  };
  hideCheckout = () => {
    this.setState({
      checkout: !this.state.checkout,
    });
  };
  updateSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
    });
  };

  render() {
    console.log(this.state.successModal);
    return (
      <this.wrapper>
        {this.props.children}
        <span className="cart-container" onClick={this.toggleCheckout}>
          <span className="cart">
            <i className="fa fa-shopping-cart"></i>
            <span className="cart-no">
              {JSON.parse(localStorage.getItem("cart-items"))
                ? JSON.parse(localStorage.getItem("cart-items")).length
                  ? JSON.parse(localStorage.getItem("cart-items")).length
                  : 1
                : 0}
            </span>
          </span>
        </span>
        <Checkout
          checkout={this.state.checkout}
          hideCheckout={() => this.hideCheckout()}
          updateSuccessModal={this.updateSuccessModal}
        />
        <SuccessModal status={this.state.successModal} />
      </this.wrapper>
    );
  }
}

export default Layout;
