import React from "react";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <section class="gallery">
      <div class="container">
        <div class="title-box mb-5">
          <h2 class="card-title-a text-black">
            Browse Gallery
            <span class="gall-see-m">
              <Link to="/projects">
                See more <i class="fa fa-arrow-right"></i>
              </Link>
            </span>
          </h2>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            <img src="img/new-batch1-10.jpg" alt="" />

            <div class="cover cover-down"></div>
          </div>

          <div class="col-md-6 col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <div class="img-container"> --> */}
            <img src="img/gallery/gallery4.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/new-batch1-2.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-md-6 col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/gallery/gallery6.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-md-6 col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/gallery/gallery7.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/gallery/gallery8.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-md-6 col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/gallery/gallery9.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-md-6 col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/new-batch1-5.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>

          <div class="col-lg-4 gallery-item">
            <div class="cover cover-up"></div>
            {/* <!-- <div class="img-container"> --> */}
            <img src="img/new-batch1-9.jpg" alt="" />
            {/* <!-- </div> --> */}

            <div class="cover cover-down"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
