import React from "react";

const Section1 = () => {
  return (
    <section class="section-services section-t8">
      <div class="">
        <div class="">
          <div class="col-md-12">
            <div class="title-wrap text-center">
              <div class="title-box">
                <h2 class="title-a"> The Party Rental Station</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="h-about-container">
          <div class="item-1">
            <img
              src="img/gallery/gallery1.jpg"
              alt="tprs events"
              style={{ objectFit: "cover" }}
            />
            <div class="my-card my-card-1">
              <h2>Wide variety of luxury items for your next event</h2>
            </div>
          </div>

          <div class="item-2">
            <img
              src="img/new-batch1-16.jpg"
              alt="tprs event"
              style={{ objectFit: "cover" }}
            />
            <div class="my-card my-card-2">
              <h2 style={{ color: "#b89952" }}>
                Make your event a memorable and colourful one.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
