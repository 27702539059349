import React, { Component } from "react";
import styled from "styled-components";
import CheckoutItem from "./checkout-item";
import axios from "axios";

const Container = styled.div`
  width: 35vw;
  z-index: 1000;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1500;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  transition: all ease 0.3s;
  @media only screen and (max-width: 900px) {
    width: 80vw;
  }
  @media only screen and (max-width: 450px) {
    width: 95vw;
  }
  .proceed {
    background: rgba(0, 0, 0, 1);
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease 0.3s;
    @media only screen and (max-width: 450px) {
      width: 70vw;
    }
    h6 {
      color: #fff;
      text-align: center;
    }
    .btn-me {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      span {
        padding: 3px 7px;
        background: #8b008b;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .clear {
    background: rgba(0, 0, 0, 1);
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease 0.3s;
    @media only screen and (max-width: 450px) {
      width: 70vw;
    }
    h6 {
      color: #fff;
      text-align: center;
    }
    .btn-me {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      span {
        padding: 3px 7px;
        background: #8b008b;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;
const Wrapper = styled.div`
  padding: 20px;
  background: #8b008b;
  color: #fff;
  font-weight: 700;
  .heading {
    display: flex;
    justify-content: space-between;
    i {
      cursor: pointer;
    }
  }
`;
const Body = styled.div`
  height: 55vh;
  overflow-y: scroll;
`;

const Bottom = styled.div`
  padding-bottom: 30px;
  .item-total {
    margin-top: 20px;
    padding-left: 20px;
    font-weight: 700;
  }
  .btn-me {
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    span {
      padding: 8px 15px;
      background: #8b008b;
      color: #fff;
      cursor: pointer;
    }
  }
`;

export default class Checkout extends Component {
  state = {
    sure: false,
    clear: false,
    products: [],
    updateCheckout: false,
  };

  componentDidMount() {
    this.getProducts();
  }

  toggleSure = (status) => {
    let item = JSON.parse(localStorage.getItem("cart-items"));
    if (item === null || item === "") {
    } else {
      this.setState({
        sure: status,
      });
    }
  };
  updateCartNow = () => {
    this.setState({
      updateCheckout: true,
    });
    console.log(this.state.updateCheckout);
  };
  toggleClear = (status) => {
    let item = JSON.parse(localStorage.getItem("cart-items"));
    console.log(item);
    if (item === null) {
      return null;
    }
    if (item.length < 1) {
      return null;
    }
    this.setState({
      clear: status,
    });
  };

  clearAll = () => {
    localStorage.removeItem("cart-items");
    this.toggleClear(false);
  };

  getProducts = () => {
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=0&order=asc`
      )
      .then((res) => {
        this.setState({
          products: res.data,
        });
        // console.log(res.data)
        // return res.data.doc
      })
      .catch((e) => {
        console.log(e);
      });
  };

  renderItems = () => {
    let item = JSON.parse(localStorage.getItem("cart-items"));

    return item === null ? (
      <h4 className="text-danger mt-3">Cart is empty!</h4>
    ) : Array.isArray(item) ? (
      item.map((product) => {
        return (
          <CheckoutItem
            id={product.id}
            name={this.getProductName(product.id)}
            quantity={product.quantity}
            updateCheckout={() => this.updateCartNow()}
          />
        );
      })
    ) : item ? (
      <CheckoutItem
        id={item.id}
        name={this.getProductName(item.id)}
        quantity={item.quantity}
        updateCheckout={() => this.updateCartNow()}
      />
    ) : null;
  };
  getProductName = (id) => {
    // console.log(this.state.products)
    // this.state.products.map(item => { return console.log(item) })
    let data = this.state.products.find((item) => {
      return item._id === id;
    });
    if (data) {
      return data.name;
    }
  };
  proceedToCheckout = () => {
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };

    // let params="";
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params1)
      .then((res) => {
        if (res.data.user === null) {
          window.location = "/login";
          // return this.props.prop.history.push('/login')
          // console.log(this.props)
        }
        const params = {
          userId: res.data.user._id,
          items: JSON.parse(localStorage.getItem("cart-items")),
        };
        // if(params.items === null){
        //     console.log("Empty")
        // }
        axios
          .post(
            "https://sheltered-cliffs-11423.herokuapp.com/api/order/create",
            params
          )
          .then((res) => {
            // this.setState({
            //     orders:res.data.doc
            // })
            console.log(res);
            this.props.updateSuccessModal();
          })
          .catch((e) => console.log(e));
      });
  };

  render() {
    // let item = JSON.parse(localStorage.getItem("cart-items"))
    // console.log(item.length)
    // console.log(this.state.products)
    return (
      <Container style={{ left: this.props.checkout ? "50%" : "-100%" }}>
        <Wrapper>
          <div className="heading">
            <span>Checkout</span>
            <span>
              <i className="fa fa-times" onClick={this.props.hideCheckout}></i>
            </span>
            {/* <span>Order # <br/>123-456-77</span> */}
          </div>
        </Wrapper>

        <Body>{this.renderItems()}</Body>
        <Bottom>
          <div className="item-total">
            Total Item:{" "}
            {JSON.parse(localStorage.getItem("cart-items"))
              ? JSON.parse(localStorage.getItem("cart-items")).length
                ? JSON.parse(localStorage.getItem("cart-items")).length
                : 1
              : 0}
          </div>

          <div className="btn-me">
            <span onClick={() => this.toggleClear(true)}>Clear</span>
            <span onClick={() => this.toggleSure(true)}>Request Order</span>
          </div>
        </Bottom>

        <div
          className="proceed"
          style={{ left: this.state.sure ? "50%" : "-150%" }}
        >
          <h6>Are you sure you want to proceed ?</h6>
          <div className="btn-me">
            <span onClick={() => this.proceedToCheckout()}>Yes</span>
            <span onClick={() => this.toggleSure(false)}>No</span>
          </div>
        </div>

        <div
          className="clear"
          style={{ left: this.state.clear ? "50%" : "-150%" }}
        >
          <h6>Are you sure you want to clear all?</h6>
          <div className="btn-me">
            <span onClick={this.clearAll}>Yes</span>
            <span onClick={() => this.toggleClear(false)}>No</span>
          </div>
        </div>
      </Container>
    );
  }
}
