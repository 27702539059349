import React, { Component } from "react";
import styled from "styled-components";
import { primaryColor } from "../../themes/colors";
import { Input2, Textarea } from "../Inputs";
import axios from "axios";

const Container = styled.div`
  z-index: 1500;
  position: fixed;
  top: 50%;
  // left:-100%;
  transform: translate(-50%, -50%);
  width: 50vw;
  padding: 30px;
  background: rgba(0, 0, 0, 0.8);
  transition: all ease 0.3s;
  h3 {
    color: #fff;
    .cancel {
      float: right;
      cursor: pointer;
    }
  }
  .field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .field-item {
      width: 48%;
      margin-top: 10px;
      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .add-new-post {
      span {
        cursor: pointer;
        float: right;
        padding: 5px 10px;
        background: ${primaryColor};
        border-radius: 4px;
        color: #fff;
        i {
          padding-left: 5px;
        }
      }
    }
  }
`;

class AddProduct extends Component {
  state = {
    name: "",
    image: "",
    msg: "",
    refresh: false,
  };

  handleInput = (item) => {
    const name = item.name;
    const value = item.value;
    switch (name) {
      case "name":
        this.setState({
          name: value,
        });
        break;

      case "image":
        this.setState({
          image: value,
        });
        break;
    }
  };

  componentDidUpdate() {
    // if(this.props.updateId !== ""){
    //     console.log(this.props.updateId)
    //     axios.get(`https://sheltered-cliffs-11423.herokuapp.com/api/singleproduct?id=${this.props.updateId}`)
    //         .then((res)=>{
    //         console.log(res)
    //         const {name,image} = res.data.doc
    //         // return this.setState({
    //         //     name:name,
    //         //     image:image
    //         // })
    //     })
    // }
  }

  componentWillReceiveProps() {
    // console.log("Receive props")
    // // if(this.props.updateId !== ""){
    //     console.log(this.props.updateId)
    //     axios.get(`https://sheltered-cliffs-11423.herokuapp.com/api/singleproduct?id=${this.props.updateId}`)
    //         .then((res)=>{
    //         console.log(res)
    //         const {name,image} = res.data.doc
    //         return this.setState({
    //             name:name,
    //             image:image
    //         })
    //     })
    // }
  }
  // shouldComponentUpdate(nextProps, nextState){
  //     console.log(nextProps, nextState)
  //     return false

  // }

  addNewProduct = () => {
    if (this.state.name === "" || this.state.image === "") {
      return this.setState({
        msg: "Input fields cannot be empty!",
      });
    }

    if (this.props.type === "add") {
      const params = {
        name: this.state.name,
        image: this.state.image,
        category: this.props.category,
      };
      axios
        .post(
          "https://sheltered-cliffs-11423.herokuapp.com/api/addproduct",
          params
        )
        .then((res) => {
          console.log(res);
          this.setState({
            name: "",
            image: "",
          });
        });
      this.props.hide(false);
      window.location.reload();
    } else if (this.props.type === "update") {
      const params = {
        _id: this.props.updateId,
        name: this.state.name,
        image: this.state.image,
        category: this.props.category,
      };
      axios
        .post(
          `https://sheltered-cliffs-11423.herokuapp.com/api/product/update`,
          params
        )
        .then((res) => {
          console.log(res);
        });
      this.props.hide(false);
    }
    // window.location.reload()
  };
  render() {
    return (
      <Container style={{ left: this.props.status ? "50%" : "-100%" }}>
        {this.props.type === "add" ? (
          <h3>
            Add New Product{" "}
            <span className="cancel" onClick={() => this.props.hide(false)}>
              <i className="fa fa-times"></i>
            </span>
          </h3>
        ) : (
          <h3>
            Edit Product{" "}
            <span className="cancel" onClick={() => this.props.hide(false)}>
              <i className="fa fa-times"></i>
            </span>
          </h3>
        )}
        {this.state.msg === "" ? null : (
          <p className="text-danger">{this.state.msg}</p>
        )}
        <div className="field">
          <div className="field-item">
            <input
              placeholder="Name"
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.handleInput(e.target)}
            />
          </div>

          <div className="field-item">
            <input
              placeholder="Image link"
              type="text"
              name="image"
              value={this.state.image}
              onChange={(e) => this.handleInput(e.target)}
            />
          </div>
        </div>

        <div className="field">
          <div className="add-new-post">
            <span onClick={this.addNewProduct}>Add</span>
          </div>
        </div>
        <form></form>
      </Container>
    );
  }
}

export default AddProduct;
