import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding:10px 20px;
    border-bottom:1px solid rgba(0,0,0,.4);
    @media only screen and (max-width:450px){
        padding:15px 10px;
    }

    .order-item{
        display:flex;
        justify-content:space-between;
        align-items:center;
        .img{
            width:70px;
            height:70px;
            background:#fff;
        }
        .desc{
            font-weight:700;
            font-size:20px;
            @media only screen and (max-width:450px){
                font-size:15px;
            }
        }
        .quantity{
            font-weight:700;
        }
        .cancel{
            i{
                cursor:pointer;
                transition:all ease .3s;
                :hover{
                    color:#8b008b;
                }
            }
        }
    }
`


const CheckoutItem = ({id,name, quantity, updateCheckout}) => {
    
    const removeItem = (removeId) =>{
        let items = JSON.parse(localStorage.getItem("cart-items"))
        if(Array.isArray(items)){
            items.filter((item, i)=>{
                if(item.id == removeId){
                    if(items.length == 1){
                        localStorage.removeItem("cart-items")
                    }else{
                        items.splice(i,1)
                        localStorage.setItem("cart-items", JSON.stringify(items))
                    }
                    
                }
                
                
            })
            console.log(items)
            
        }else{
            localStorage.removeItem("cart-items")
        }
        updateCheckout()
        // console.log("clicked")
    }
    return (
        <Container>
            <div className="order-item">
                <span className="img"> <img src="" alt=""/></span>
                <span className="desc">{name}</span>
                <span className="quantity">{quantity} pcs</span>
                <span className="cancel"><i className="fa fa-times" onClick={() => removeItem(id)}></i></span>
            </div>
        </Container>
    );
}

export default CheckoutItem;
