import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PDF from "react-pdf-js";

const PDFViewerWrapper = styled.div`
  width: 100%;
  padding: 0 10px;

  canvas {
    width: 100% !important;
    height: 600px !important;
    object-fit: contain;
  }

  .page-number {
    white-space: nowrap;
    margin: 0;
  }
`;

const PDFViewer = ({ file }) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(true);

  const onDocumentComplete = (pages) => {
    setTotalPages(pages);
    setLoading(false);
  };

  const handlePageChange = (type) => {
    if (type === "next") {
      setPage(page + 1);
    }

    if (type === "prev") {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [file]);

  return (
    <PDFViewerWrapper>
      {loading && <h4 className="text-center py-4">Loading...</h4>}
      <PDF file={file} onDocumentComplete={onDocumentComplete} page={page} />
      <div className="d-flex align-items-center">
        <div className="container">
          <span
            onClick={() => handlePageChange("prev")}
            className={`my-prev ${page === 1 ? "text-fade" : ""}`}
          >
            Prev
          </span>{" "}
          <span
            onClick={() => handlePageChange("next")}
            className={`my-next ${page >= totalPages ? "text-fade" : ""}`}
          >
            Next
          </span>
        </div>
        <p className="page-number">
          Page {page} of {totalPages}
        </p>
      </div>
    </PDFViewerWrapper>
  );
};

export default PDFViewer;
