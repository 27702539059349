import React, { Component } from "react";
import * as UI from "./style";
import Box1 from "../../components/Box-1";
import { Link } from "react-router-dom";
import AdminDashboardEssentials from "../../components/Admin-Dashboard-Essentials";
import AddPost from "../../components/AddPost";
import SureDelete from "../../components/SureDelete";
import axios from "axios";
export default class AdminDashboardOrder extends Component {
  state = {
    addPost: false,
    confirmAction: false,
    confirmActionType: "",
    orders: "",
    user: "",
    statusUpdate: "",
    updateOrderId: "",
    skip: 0,
    users: "",
    refresh: false,
  };

  componentDidMount() {
    // console.log(this.props.match.params)
    const auth = localStorage.getItem("auth");
    const params1 = {
      token: auth,
    };
    let params = "";

    axios
      .post(
        "https://sheltered-cliffs-11423.herokuapp.com/api/admin/auth",
        params1
      )
      .then((res) => {
        if (res.data.user === null) {
          return this.props.history.push("/login");
        }
        let URL = this.selectOrderType(this.props.match.params.order);
        // console.log(this.props.match.params)
        axios
          .get(URL)
          .then((res) => {
            // console.log(res)
            this.setState({
              orders: res.data.doc,
            });
          })
          .catch((e) => console.log(e));
      });

    this.getProducts();
    this.fetchAllUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refresh !== this.state.refresh) {
      let URL = this.selectOrderType(this.props.match.params.order);
      axios
        .get(URL)
        .then((res) => {
          // console.log(res)
          this.setState({
            orders: res.data.doc,
          });
        })
        .catch((e) => console.log(e));
    }
  }
  fetchAllUser = () => {
    axios
      .get("https://sheltered-cliffs-11423.herokuapp.com/api/allusers")
      .then((res) => {
        // console.log(res.data.doc)
        this.setState({
          users: res.data.doc,
        });
      })
      .catch((e) => console.log(e));
  };
  getProducts = (id) => {
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/multipleproduct?skip=0&order=asc`
      )
      .then((res) => {
        this.setState({
          products: res.data,
        });
        // console.log(res.data)
        // return res.data.doc
      })
      .catch((e) => {
        console.log(e);
      });
  };

  selectOrderType = (type) => {
    let URL = "";
    switch (type) {
      case "all":
        URL = `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=5&order=desc`;
        break;
      case "accepted":
        URL = `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=5&order=desc&status=accepted`;
        break;
      case "rejected":
        URL = `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=5&order=desc&status=rejected`;
        break;
      case "cancelled":
        URL = `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=5&order=desc&status=cancelled`;
        break;
      case "pending":
        URL = `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=5&order=desc&status=pending`;
        break;
      default:
        URL = `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=${this.state.skip}&limit=5&order=desc`;
    }
    return URL;
  };
  toggleAddPost = (value) => {
    this.setState({
      addPost: value,
    });
  };

  toggleDeletePost = (value) => {
    this.setState({
      confirmAction: value,
    });
  };

  renderIndividualOrders = (items) => {
    return items.map((item, i) => {
      return (
        <li className="single-item">
          <span className="item-name">
            <h5>Name:</h5> <p>{this.getProductName(item.id)}</p>
          </span>

          <span className="item-quantity">
            <h5>Quantity:</h5> <p>{item.quantity}</p>
          </span>
        </li>
      );
    });
  };

  renderStatus = (item) => {
    let template = "";
    switch (item) {
      case "accepted":
        template = <p className="status success"> {item}</p>;
        break;
      case "cancelled":
        template = <p className="status cancelled"> {item}</p>;

      case "rejected":
        template = <p className="status cancelled"> {item}</p>;
        break;
      case "pending":
        template = <p className="status pending"> {item}</p>;
        break;
      default:
        template = <p className="status pending"> {item}</p>;
    }
    return template;
  };

  getUser = (userId) => {
    let template = "";
    const params = {
      id: userId,
    };
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/getUser", params)
      .then((res) => {
        const { name, email, phone } = res.data;
        // console.log(name)
        // this.setState({
        //     user:name
        // })
        template = name;
        this.renderUser(name, email, phone);
        // return <ul>
        //         <li>Name:{name}</li>
        //         <li>{email}</li>
        //         <li>{phone}</li>
        //     </ul>
      });

    // return <ul>
    //             <li>Name:{this.state.user.name}</li>
    //             <li>{template.email}</li>
    //             <li>{template.phone}</li>
    //         </ul>
  };

  renderUser = (name, email, phone) => {
    return (
      <ul>
        <li>{name}</li>
        <li>{email}}</li>
        <li>{phone}</li>
      </ul>
    );
  };
  handleStatusUpdate = (value, type, id) => {
    this.setState({
      confirmAction: value,
      confirmActionType: type,
      updateOrderId: id,
    });
  };
  updateStatus = (status) => {
    const params = {
      _id: this.state.updateOrderId,
      status: status,
      read: true,
    };
    axios
      .post(
        "https://sheltered-cliffs-11423.herokuapp.com/api/order/update",
        params
      )
      .then((res) => {
        console.log(res);
        this.setState({
          confirmAction: false,
          refresh: true,
        });
        this.setState({
          refresh: false,
        });
      });
  };
  getProductName = (id) => {
    let data = this.state.products.find((item) => {
      return item._id === id;
    });
    if (data) {
      return data.name;
    }
  };

  getUserDetails = (id) => {
    let data = this.state.users.find((item) => {
      return item._id === id;
    });
    if (data) {
      return {
        name: data.name,
        phone: data.phone,
        email: data.email,
      };
    }
  };
  renderOrders = () => {
    const orders = this.state.orders;
    // console.log(orders)
    return orders
      ? orders.map((item, i) => {
          // this.getUser(item.userId)
          return (
            <tr key={i}>
              <td>{item._id}</td>
              <td>{this.renderIndividualOrders(item.items)}</td>
              <td>{this.renderStatus(item.status)}</td>
              <td>{item.createdAt}</td>
              <td>
                <li className="user-info">
                  <h6>Name:</h6>{" "}
                  <span>{this.getUserDetails(item.userId).name}</span>
                </li>
                <li className="user-info">
                  <h6>Email:</h6>{" "}
                  <span>{this.getUserDetails(item.userId).email}</span>
                </li>
                <li className="user-info">
                  <h6>Phone:</h6>{" "}
                  <span>{this.getUserDetails(item.userId).phone}</span>
                </li>
              </td>
              <td>
                {item.read ? (
                  <span>seen</span>
                ) : item.status === "cancelled" ? (
                  <span>seen</span>
                ) : (
                  <div>
                    <span
                      className="accept-order"
                      onClick={() =>
                        this.handleStatusUpdate(true, "accept", item._id)
                      }
                    >
                      Accept
                    </span>{" "}
                    <span
                      className="reject-order"
                      onClick={() =>
                        this.handleStatusUpdate(true, "reject", item._id)
                      }
                    >
                      Reject
                    </span>
                  </div>
                )}
              </td>
            </tr>
          );
        })
      : null;
  };

  navigateOrders = (action) => {
    if (action === "next") {
      this.setState({
        skip: this.state.skip + 6,
      });
    } else {
      this.setState({
        skip: this.state.skip + 6,
      });
    }
  };
  render() {
    //    console.log(this.state.user)
    return (
      <UI.Container>
        {/* <AddPost status={this.state.addPost} hide={() => this.toggleAddPost()}/> */}

        {this.state.confirmActionType === "accept" ? (
          <SureDelete
            status={this.state.confirmAction}
            hide={() => this.toggleDeletePost()}
            text="Are you sure you want to accept offer?"
            proceed={() => this.updateStatus("accepted")}
          />
        ) : (
          <SureDelete
            status={this.state.confirmAction}
            hide={() => this.toggleDeletePost()}
            text="Are you sure you want to reject offer?"
            proceed={() => this.updateStatus("rejected")}
          />
        )}

        <AdminDashboardEssentials prop={this.props}>
          <div className="container">
            <div className="top">
              <h2>View Orders</h2>
              {/* <div className="add-new-post">
                                <span onClick={() => this.toggleAddPost(true)}>Add new Item <i className="fa fa-plus"></i></span>
                            </div> */}
            </div>

            <UI.Wrapper>
              <div className="posts">
                <div className="table table-bordered">
                  <table>
                    <tbody>
                      <tr>
                        <th>Order ID.</th>
                        <th>Items</th>
                        <th>Status</th>
                        <th>Date </th>
                        <th>Order by:</th>
                        <th>Update status</th>
                        {/* <th>Mark as read</th> */}
                      </tr>
                      {this.renderOrders()}
                    </tbody>
                  </table>
                </div>
              </div>
            </UI.Wrapper>
          </div>

          <div className="navigate">
            <p
              onClick={() => this.navigateOrders("next")}
              style={{ fontWeight: "600", marginRight: "15px" }}
            >
              Next
            </p>
            <p
              onClick={() => this.navigateOrders("prev")}
              style={{ fontWeight: "600" }}
            >
              Prev
            </p>
          </div>
        </AdminDashboardEssentials>
      </UI.Container>
    );
  }
}

// image1
// image2
// tag
// title
// desc
// author
