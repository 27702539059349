import React from 'react';
import styled from 'styled-components';
import {primaryColor} from '../../themes/colors';
import {Input1, Textarea} from '../Inputs';

const Container = styled.div`
    z-index:1500;
    position:fixed;
    top:50%;
    // left:50%;
    transform:translate(-50%,-50%);
    width:50vw;
    padding:30px;
    background:rgba(0,0,0,.8);
    transition:all ease .3s;
    @media only screen and (max-width:900px){
        width:60vw;
    }
    @media only screen and (max-width:450px){
        width:80vw;
    }
    h5{
        color:#fff;
        font-family:montserrat;
        text-align:center
    }
    .field{
        display:flex;
        justify-content:center;
        margin-bottom:20px;
        
        .add-new-post{
            span{
                cursor:pointer;
                float:right;
                padding:5px 15px;
                background:${primaryColor};
                border-radius:4px;
                color:#fff;
                margin:0 10px;
                i{
                    padding-left:5px;
                }
            }
        }
    }
`;
const AddPost = ({status, hide, text, proceed}) => {
    return (
        <Container style={{left:status?"50%":"-100%"}}>
            <h5>{text}</h5>
            

            <div className="field">
                <div className="add-new-post">
                    <span onClick={() => (proceed())}>Yes</span>
                </div>

                <div className="add-new-post">
                    <span onClick={()=>hide(false)}>No</span>
                </div>
                
            </div>
            <form>

            </form>
        </Container>
    );
}

export default AddPost;
