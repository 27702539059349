import styled from 'styled-components';
export const Container = styled.div`
    .wrapper{
        display:flex;
    }
`;

export const Left = styled.div`
    width:50vw;
    @media only screen and (max-width:900px){
        width:30vw;
    }
`;

export const Right = styled.div`
    padding-top:30px;
    width:50vw;
    display:flex;
    justify-content:center;
    align-items:center;
    @media only screen and (max-width:900px){
        width:70vw;
    }
    .right-container{
         width:60%;
    }
    .logo{
        width:fit-content;
        margin:0 auto;
        img{
            height:70px;
        }
    }
    form{
       
        margin:50px auto;
        .form-item{
            margin-bottom:30px;
        }
        span{
             border:1px solid #8b008b;
             border-right:none;
             padding:4.5px 8px;
             i{
                 color:#8b008b;
             }
        }
        input{
            width:90%;
            height:40px;
            padding-left:15px;
            padding-right:15px;
            outline:none;
            border:1px solid #8b008b;
            @media only screen and (max-width:900px){
                width:85%;
            }
            ::placeholder{
                color:#8b008b;
            }
        }
        .form-btn{
            width: fit-content;
            margin: 0 auto;
            span{
                border:1px solid #8b008b;
                padding:5px 10px;
                font-size:18px;
                background:#8b008b;
                cursor:pointer;
                color:#fff;
                border-radius:4px;
                transition:all ease .3s;
                :hover{
                    background:#fff;
                    cursor:pointer;
                    color:#8b008b;
                }
            }
        }
        .text{
            margin-top:30px;
            text-align:center;
            a{
                color:#8b008b;
                font-weight:600;
            }
        }
    }
`;
