import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    margin-bottom:20px;
`
const Wrapper = styled.div`
    a{
        color:#fff;
        padding:10px 20px;
        span{
            margin-right:20px;
        }
    }
    .active-me{
       background:rgba(0,0,0,.4);
       width:100%;
       display:block;
      
    }
`
const Menu = ({name, link, icon}) => {
    return (
        <Container>
            <Wrapper>
                <NavLink to={link} activeClassName="active-me"><span><i className={`fa fa-${icon}`}></i></span>{name}</NavLink>
            </Wrapper>
            
        </Container>
    );
}

export default Menu;
