import React, { Component } from "react";
import * as UI from "./style";
import Menu from "../Menu";
import Box1 from "../../components/Box-1";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";

class DashboardEssentials extends Component {
  state = {
    user: "",
  };

  componentDidMount(props) {
    const auth = localStorage.getItem("auth");
    if (!auth || auth === "") {
      // console.log(this.props.location)
      this.props.prop.history.push("/login");
    } else {
      const auth = localStorage.getItem("auth");
      const params = {
        token: auth,
      };
      axios
        .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params)
        .then((res) => {
          if (res.data.user === null) {
            return this.props.prop.history.push("/login");
          }
          this.setState({
            user: res.data.user,
          });
        });
    }
    // else{
    //     re
    // }
  }
  render() {
    return (
      <div>
        {/* <UI.Header>
                    <div className="logo-container">
                        <div className="logo">
                            <img src={require('../../assets/img/logo.svg')} alt="glint football"/>
                            <Link to="/" style={{color:'white',textDecoration:"none"}}>
                                <h1>Glint Football</h1>
                            </Link>
                        </div>
                    </div>
                </UI.Header> */}
        <Header />

        <UI.Content>
          <UI.SideNav>
            <Menu name="Dashboard" link="/dashboard" icon="dashboard" />
            <Menu name="Orders" link="/orders" icon="dashboard" />
          </UI.SideNav>

          <UI.Body>
            <div className="user">
              <span className="online"></span>Hello,{" "}
              <h6 style={{ display: "inline-block" }}>
                {this.state.user.name}
              </h6>
            </div>
            {this.props.children}
          </UI.Body>
        </UI.Content>
      </div>
    );
  }
}

export default DashboardEssentials;
