import React, { Component } from "react";
import * as UI from "./style";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Section1 from "../../components/Section1";
import Gallery from "../../components/Gallery";
import Rental from "../../components/Rentals";
import Footer from "../../components/Footer";

export default class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <UI.Container>
        <Header />
        <Banner />
        <Section1 />
        <Rental />
        <Gallery />
        <Footer />
      </UI.Container>
    );
  }
}
