import React, { Component } from "react";
import Slider from "react-slick";
import RentalItem from "../Rentals/rental-item";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <RentalItem
              img="img/furniture/black-dior.jpg"
              name="Speciality Furnitures"
              type="Chairs"
              link="/item/speciality-furniture"
            />
          </div>
          <div>
            <RentalItem
              img="img/lightening/beam-230.jpg"
              name="Lightening and Special effects"
              type="Light"
              link="/item/lightening"
            />
          </div>
          <div>
            <RentalItem
              img="img/linen/gold-rossete.jpg"
              name="Linen Collections"
              type="Table Linen"
              link="/item/linen"
            />
          </div>
          <div>
            <RentalItem
              img="img/kiddies/kiddies-2.jpg"
              name="Kiddies Collection"
              type="Kiddies"
              link="/item/kiddies"
            />
          </div>
          <div>
            <RentalItem
              img="img/mobile-tent/dome-tent.jpg"
              name="Mobile Tents and Canopies"
              type="Mobile Tent"
              link="/item/tent-canopies"
            />
          </div>
          <div>
            <RentalItem
              img="img/luxury/black-oz.jpg"
              name="Luxury Chairs"
              type="Chair"
              link="/item/wedding-chairs-table"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
