import React from 'react';
import styled from 'styled-components';

const Container1 = styled.input`
    width:100%;
    border-radius:4px;
    outline:none;
    font-family:montserrat;
`;
const Container2 = styled.textarea`
    width:100%;
    border-radius:4px;
    outline:none;
    font-family:montserrat;
    height:150px;
    padding:10px;
`;

export const Input1 = ({placeholder,value,icon,type, name, handleInput}) => {
    return (
        <div className="form-item">
            <span><i className={icon}></i></span><input type={type} value={value} placeholder={placeholder} name={name} onChange={(e)=> (handleInput(e))} required />
        </div>
    );
}

export const Input2 = ({value, name, type,placeholder}) => {
    return (
        <Container1 type={type} placeholder={placeholder} name={name} value={value}>
            
        </Container1>
    );
}

export const Textarea = ({value, name,placeholder}) => {
    return (
        <Container2 placeholder={placeholder} name={name} value={value}>
            
        </Container2>
    );
}