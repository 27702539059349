import React, { Component } from "react";
import * as UI from "./style";
import { Link, Redirect } from "react-router-dom";
import { Input1 } from "../../components/Inputs";
import axios from "axios";

export default class AdminLogin extends Component {
  state = {
    username: "",
    password: "",
    msg: "",
  };

  handleInput = (e) => {
    switch (e.target.name) {
      case "username":
        this.setState({
          username: e.target.value,
        });
        break;

      case "password":
        this.setState({
          password: e.target.value,
        });
        break;
    }
  };
  submitForm = (e) => {
    const { username, password } = this.state;
    if (username === "" || password === "") {
      return this.setState({
        msg: "Fields cannot be Empty!",
      });
    }

    const params = {
      username: username,
      password: password,
    };

    axios
      .post(
        "https://sheltered-cliffs-11423.herokuapp.com/api/admin/login",
        params
      )
      .then((res) => {
        this.setState({
          msg: res.data.message,
        });
        if (res.data.auth) {
          localStorage.setItem("auth", res.data.token);
          this.props.history.push("/admin/dashboard");
        }
        console.log(res);
      })
      .catch((e) => console.log(e));
  };
  render() {
    return (
      <UI.Container>
        {/* Login */}
        <div className="wrapper">
          <UI.Left>
            <div
              style={{
                background: "url(../img/gallery/ban1.jpg)",
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </UI.Left>

          <UI.Right>
            <div className="right-container">
              <div className="logo">
                <Link to="/">
                  <img src="../img/tprs.png" alt="tprs" />
                </Link>
                <h2>ADMIN LOGIN</h2>
              </div>

              <form>
                {this.state.msg !== "" ? (
                  <div className="text-danger">{this.state.msg}</div>
                ) : null}
                <Input1
                  type="email"
                  value={this.state.email}
                  placeholder="Username"
                  icon="fa fa-envelope"
                  name="username"
                  handleInput={(e) => this.handleInput(e)}
                />

                <Input1
                  type="password"
                  value={this.state.password}
                  placeholder="Password"
                  icon="fa fa-key"
                  name="password"
                  handleInput={(e) => this.handleInput(e)}
                />

                <div className="form-btn">
                  <span onClick={() => this.submitForm()}>Login</span>
                </div>
                {/* <p className="text">Don't have an account yet? <Link to="/register">Register</Link></p> */}
              </form>
            </div>
          </UI.Right>
        </div>
      </UI.Container>
    );
  }
}
