import React from 'react';
import styled from 'styled-components';
// import {primaryColor} from '../../themes/colors';
const Container = styled.div`
    margin-bottom:20px;
    
`

const Wrapper = styled.div`
    // margin-bottom:20px;
    padding:25px 20px;
    background:#8b008b;
    max-height:200px;
    border-radius:10px;
    
    .icon{
        margin-right:20px;
        color:#fff;
        font-size:30px;
        width:fit-content;
        margin:0 auto;
    }
    h3{
        color:#fff;
        margin-bottom:20px;
        text-align:center;
        font-size:20px;
    }
    h4{
        color:#fff;
        font-size:35px;
        text-align:center;
    }
`

const Box1 = ({heading, value, icon}) => {
    console.log(value)
    return (
        <Container>
            <Wrapper>
                {/* <div> */}
                    <div className="icon"><i className={`fa fa-${icon}`}></i></div>
                    <h3><span className="text">{heading}</span></h3>
                    <h4>{value ? value:0}</h4>
                {/* </div> */}
                

            </Wrapper>
        </Container>
    );
}

export default Box1;
