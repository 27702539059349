import React, { Component } from "react";
// import * as UI from './style';
import { Link } from "react-router-dom";
import RentalItem from "./rental-item";
import Slider from "../Slider-2";

export default class Rental extends Component {
  render() {
    return (
      <section class="section-property section-t8">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                  <h2 class="title-a">Rental Services</h2>
                </div>

                <div class="title-link" style={{ whiteSpace: "nowrap" }}>
                  <Link to="/item/speciality-furniture">
                    View More
                    <span class="ion-ios-arrow-forward"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Slider />
          </div>
        </div>
      </section>
    );
  }
}
