import React, { Component } from "react";
// import * as UI from './style';
import { Link } from "react-router-dom";
import Header from "../../components/Header";
// import Banner from '../../components/Banner';
// import Section1 from '../../components/Section1'
// import Gallery from '../../components/Gallery';
// import Rental from '../../components/Rentals';
import Footer from "../../components/Footer";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import Carousel from '../../components/Slider';

export default class About extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        {/* <Banner/> */}
        {/* <Section1/>
                <Rental/>
                <Gallery/> */}
        {/* <UI.Body>
                    <UI.Content className="container">
                        <h2 className="h-services">At The Party Rental Station</h2>
                        <p  className="p-services">Some of the services we provide to our customers includes;</p>
                        <Carousel/>
                    </UI.Content>
                    
                </UI.Body> */}

        <section class="intro-single">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-8">
                <div class="title-single-box">
                  <h1 class="title-single">The Party Rentals Station (TPRS)</h1>
                </div>
              </div>
              <div class="col-md-12 col-lg-4">
                <nav
                  aria-label="breadcrumb"
                  class="breadcrumb-box d-flex justify-content-lg-end"
                >
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link href="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      About
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section class="section-about">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="about-img-box">
                  <img src="img/ban6.jpg" alt="" class="img-fluid" />
                </div>
                <div class="sinse-box">
                  <h3 class="sinse-title" style={{ color: "#fff" }}>
                    luxury event Items and décor solutions.
                    <span></span>
                    {/* <!-- <br> Sinse 2017 --> */}
                  </h3>
                  {/* <!-- <p>Art & Creative</p> --> */}
                </div>
              </div>
              <div class="col-md-12 section-t8">
                <div class="row">
                  <div class="col-md-6 col-lg-5">
                    <img src="img/gallery/ban1.jpg" alt="" class="img-fluid" />
                  </div>
                  <div class="col-lg-1  d-none d-lg-block">
                    {/* <!-- <div class="title-vertical d-flex justify-content-start">
                                <span>EstateAgency Exclusive Property</span>
                            </div> --> */}
                  </div>
                  <div class="col-md-6 col-lg-6 section-md-t3">
                    <div class="title-box-d">
                      <h3 class="title-d color-b">
                        Get
                        <span class="color-b"> to </span> Know More
                        <span class="color-b" style={{ color: "#000" }}>
                          {" "}
                          About Us
                        </span>
                        {/* <!-- <br> nibh. --> */}
                      </h3>
                    </div>
                    <p class="color-text-a">
                      The Party Rentals Station (TPRS) is Stylish Event Rental
                      Company offering the most unique line of luxury décor
                      items and event solutions.
                      <br />
                    </p>
                    <p class="color-text-a">
                      Our Collection includes a variety of the finest and
                      carefully selected luxury chairs, VVIP Tables,Mobile
                      Tents, Mist fans/Air Coolers, Cocktail tables, bar stools,
                      Upholstered Lounge sofas,Event lighting, Special effects,
                      Tableware, and lots more.
                    </p>
                    <p class="color-text-a">
                      Whether you are a Wedding Planner, Event Manager, Event
                      Designer, Photographer, Industry Vendor, or
                      Do-It-Yourselfer, Our In-House Team of Creative Event
                      Professionals are available to offer you everything you
                      need from our line of exclusive products designed to make
                      your Event as special as possible.
                    </p>

                    <p class="color-text-a">
                      At the Party Rental Station, we pride ourselves in
                      offering exceptional customer service as well as excellent
                      service delivery. Our team is here to help ensure that
                      your rental process is seamless and easy.
                    </p>

                    {/* <!-- <p class="color-text-a">
                                The Party Rental Station prides herself in offering the most unique products in the rental market as well as exceptional customer service. Our team is here to help insure that your rental process is seamless and easy.
                            </p> --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-about mt-5 pt-5">
          <div class="container">
            <h2 class="title-b text-center mb-5">Our Mission & Vision</h2>
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "20px" }}
                >
                  <img
                    alt="tprs"
                    src={require("../../assets/img/target.png")}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <p className=" mt-3">
                    To be a No 1 party rental benchmark company and a house hold
                    name in all major cities and in Nigeria.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "20px" }}
                >
                  <img
                    alt="tprs"
                    src={require("../../assets/img/target.png")}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <p className=" mt-3">
                    To deliver exceptional service while ensuring consistency in
                    the quality of our inventory.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-about mt-5 pt-5">
          <div class="container">
            <h2 class="title-b mb-5 text-center">Our Core Values</h2>
            <div className="row">
              <div className="col-lg-4 mb-xs-4">
                <img
                  alt="tprs"
                  className="d-block mx-auto"
                  src={require("../../assets/img/five-stars.png")}
                  style={{ width: "100px", height: "100px" }}
                />
                <h5 className="text-center mt-3">Professionalism</h5>
              </div>

              <div className="col-lg-4  mb-xs-4">
                <img
                  alt="tprs"
                  className="d-block mx-auto"
                  src={require("../../assets/img/excellence.png")}
                  style={{ width: "100px", height: "100px" }}
                />
                <h5 className="text-center mt-3">Excellent service delivery</h5>
              </div>

              <div className="col-lg-4  mb-xs-4">
                <img
                  alt="tprs"
                  className="d-block mx-auto"
                  src={require("../../assets/img/trophy-star.png")}
                  style={{ width: "100px", height: "100px" }}
                />
                <h5 className="text-center mt-3">Consistency</h5>
              </div>
            </div>
          </div>
        </section>
        <div className="pb-md-5"></div>
        <Footer />
      </div>
    );
  }
}
