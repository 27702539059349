import styled from 'styled-components';
import {primaryColor} from '../../themes/colors';
export const Container = styled.div`
    // overflow-y:scroll;
`;

export const Header = styled.div`
    background:#000;
    position:fixed;
    top:0;
    left:0;
    right:0;
    padding:30px;
    z-index:500;
    .logo-container{
        .logo{
            display:flex;
            align-items:center;
            margin:0 auto;
            width:fit-content;
            @media only screen and (max-width:800px){
                margin:0;
            }
            img{
                margin-right:10px;
                height:50px;
                @media only screen and (max-width:450px){
                    height:50px;
                    margin-right:6px;
                }
            }
            h1{
                font-size:30px;
                font-family:GothamMedium;
                @media only screen and (max-width:450px){
                    font-size:22px;
                }
            }
        }
        // width:50%;
        @media only screen and (max-width:500px){
            width:80%;
        }
    }
`;
export const Content = styled.div`
    height:90vh;
`;
export const SideNav = styled.div`
    z-index:500;
    background:#8b008b;
    width:20%;
    padding:30px 0px;
    height:100%;
    position:fixed;
    top:130px;
    bottom:0;
    overflow-y:scroll;
    color:#fff;
`;

export const Body = styled.div`
    height:120vh;
    padding-top: 150px;
    padding-left: 20%;
    padding-bottom:40px;
    position:relative;
    .user{
        padding-left:20px;
        margin-bottom:20px;
        .online{
            display:inline-block;
            margin-right:5px;
            width:15px;
            height:15px;
            border-radius:50%;
            background:green;
        }
    }
    
`;