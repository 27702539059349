import React, { Component } from "react";
import clsx from "classnames";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { links } from "../../utils/navLinks";

const NavIcon = styled.div`
  @media only screen and (min-width: 992px) {
    display: none;
  }
  i {
    font-size: 30px;
    margin-left: 10px;
  }
`;

class Header extends Component {
  state = {
    user: "",
    showNav: false,
    subNav: false,
  };
  componentDidMount() {
    const auth = localStorage.getItem("auth");

    const params = {
      token: auth,
    };
    axios
      .post("https://sheltered-cliffs-11423.herokuapp.com/api/auth", params)
      .then((res) => {
        this.setState({
          user: res.data.user,
        });
      })
      .catch((e) => console.log(e));
    // console.log(show)
    // return show ? <Button2/> : <Button1/>
  }

  logout = () => {
    localStorage.removeItem("auth");
  };
  Button1 = () => {
    return (
      <div>
        <span>
          <Link to="/login" className="acc-btn login">
            Login
          </Link>
        </span>
        <span>
          <Link to="/register" className="acc-btn register">
            Register
          </Link>
        </span>
      </div>
    );
  };

  Button2 = () => {
    return (
      <div>
        <span>
          <Link to="/" className="acc-btn login" onClick={() => this.logout()}>
            Logout
          </Link>
        </span>
      </div>
    );
  };

  renderBtn = () => {
    const auth = localStorage.getItem("auth");
    let show = "";
    if (!auth || auth == "") {
      return <this.Button1 />;
    } else {
      return (
        <span style={{ display: "flex" }}>
          <span>
            Welcome
            <Link to="/dashboard">
              <h6
                style={{
                  display: "inline-block",
                  marginLeft: "5px",
                  color: "blue",
                  textDecoration: "underline",
                  marginRight: "15px",
                }}
              >
                {this.state.user ? this.state.user.name : ""}
              </h6>
            </Link>
          </span>
          <this.Button2 />
        </span>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="click-closed"></div>

        {/* Nav Star */}
        <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
          <div className="container">
            <NavIcon
              onClick={() => {
                this.setState((prevState) => ({
                  showNav: !prevState.showNav,
                  subNav: false,
                }));
              }}
            >
              <i className="fa fa-bars"></i>
            </NavIcon>
            <a className="navbar-brand text-brand" href="/">
              <img
                src={require("../../assets/img/tprs.png")}
                alt="TPRS rentals"
                style={{ width: "150px" }}
              />
            </a>

            <div
              className={clsx(
                "navbar-collapse navbar-header justify-content-center",
                {
                  "navbar-header-hide": !this.state.showNav,
                  "subnav-active": this.state.subNav,
                }
              )}
              id="navbarDefault"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    exact
                    className="nav-link"
                    activeClassName="active"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about" className="nav-link">
                    About
                  </NavLink>
                </li>

                <li
                  className="nav-item dropdown"
                  onClick={() =>
                    this.setState({
                      subNav: !this.state.subNav,
                    })
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Rentals
                  </a>
                  <div
                    className={clsx("dropdown-menu", {
                      "dropdown-menu-on-mobile": this.state.subNav,
                    })}
                    aria-labelledby="navbarDropdown"
                  >
                    {links.map((item) =>
                      item === "kiddies'-collections" ? (
                        <a
                          className="dropdown-item"
                          style={{ textTransform: "capitalize" }}
                          rel="noreferrer"
                          href={"https://tprskiddies.com/"}
                          target="_blank"
                        >
                          {item.replaceAll("-", " ")}
                        </a>
                      ) : (
                        <Link
                          className="dropdown-item"
                          to={`/item/${item}`}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.replaceAll("-", " ")}
                        </Link>
                      )
                    )}
                    {/* <Link
                      className="dropdown-item"
                      to="/item/speciality-furniture"
                    >
                      Speciality Chairs
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/item/wedding-chairs-table"
                    >
                      Tables
                    </Link>
                    <a
                      href="https://tprskiddies.com/"
                      className="dropdown-item"
                      to="/item/kiddies"
                      target="_blank"
                    >
                      Kiddies’ collections
                    </a>
                    <Link
                      className="dropdown-item"
                      to="/item/upholster-furniture"
                    >
                      Upholster Furniture (Lounge sofas)
                    </Link>
                    <Link className="dropdown-item" to="/item/lightening">
                      Lighting and special effects
                    </Link>
                    <Link className="dropdown-item" to="/item/linen">
                      Linen collections
                    </Link>
                    <Link className="dropdown-item" to="/item/crockeries">
                      Crockeries{" "}
                    </Link>
                    <Link className="dropdown-item" to="/item/tent-canopies">
                      Mobile tents and Canopies
                    </Link>
                    <Link className="dropdown-item" to="/item/flooring-carpets">
                      Floorings and Carpets
                    </Link>
                    <Link className="dropdown-item" to="/item/furniture-props">
                      Rustic Furniture and props
                    </Link>
                    <Link className="dropdown-item" to="/item/flowers">
                      Flowers{" "}
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/item/centerpiece-pedestal"
                    >
                      Centerpieces and pedestals
                    </Link>
                    <Link className="dropdown-item" to="/item/cake-tables">
                      Cake tables
                    </Link>
                    <Link className="dropdown-item" to="/item/others">
                      Others{" "}
                    </Link>*/}
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink to="/projects" className="nav-link">
                    Projects
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>

            <div
              className={`social-me ${
                !this.state.showNav ? "social-me-hide" : ""
              }`}
            >
              {this.renderBtn()}
            </div>
          </div>
        </nav>
        {/* Nav End  */}
      </div>
    );
  }
}

export default Header;
