import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Wrapper } from "./style";
import FaqItem from "../../components/FaqItem";

export default function Faqs() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [active, setActive] = useState(null);
  const faqs = [
    {
      question: "Do you do both indoor and Outdoor Hires",
      answer:
        "Most of our equipment (unless specifically stated against each product) can be used inside or outside. Please also note that during raining season, we do not permit outdoor hires due to seasonal weather conditions which can compromise the safety of users and also damage our items. If you must use them out door, a fully covered tent or marquee must be available to protect them",
    },
    {
      question: "How many hours can I hire the equipment for?",
      answer:
        "All rental rates are for one day use only. If extra days are required, please speak to us ahead and we will do our best to accommodate you at an additional charge.",
    },
    {
      question:
        "What is service charge and why is it different from delivery fee",
      answer:
        "Service fee is charged if we are to setup items at your venue and pack up after the event. Kindly note that all items must be setup by us except its stated otherwise on the invoice. The Delivery Fee is charged as the cost of transportation of items to the location and back to our warehouse after the event.",
    },
    {
      question:
        "Can I pick up my items from your warehouse and return them after my event?",
      answer:
        "We do not allow pickup of items from our warehouse because of the possibility of customers mishandling our items. All items must be delivered and retrieved by our staff.",
    },
    {
      question:
        "If my venue is upstairs, will you be able to setup? Is there any extra charge for this?",
      answer:
        "All items can be setup on a higher floor except if stated otherwise in the item’s description. However, there will be an extra fee charged for this. Please call us to discuss our options as this could vary depending on the circumstances.",
    },
    {
      question: "Can you guarantee arrival times?",
      answer:
        "We ALWAYS endeavor to arrive promptly at the agreed time, however, on VERY rare occasion due to circumstances beyond our control, we cannot promise 100% that we will always arrive at the exact time requested",
    },
    {
      question: "What do I need to do prior to your arrival?",
      answer:
        "A: Kindly ensure that the setup space is clearly mapped out and free of any obstructions to avoid setup delays.",
    },
    {
      question: "Is there a charge if the equipment is damaged or missing?",
      answer:
        "We understand that items may not stay in the exact same condition due to use, However, we advise that Items are to be handled with care during your party. In the event of loss or damage of our item(s), we will retain your refundable deposit and issue additional invoice to cover the cost of loss or damage.",
    },
  ];

  console.log(active);
  return (
    <Wrapper>
      <Header />
      <section className="intro-single">
        <div className="container">
          <h1>FAQs</h1>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div>
            {faqs.map((item) => (
              <div key={item.question}>
                <FaqItem {...item} onClick={setActive} active={active} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </Wrapper>
  );
}
