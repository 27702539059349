import React from "react";
import { Link } from "react-router-dom";
import { socials } from "../../utils/social";
import { links } from "../../utils/navLinks";

const Footer = () => {
  return (
    <div>
      <section class="section-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <div class="widget-a">
                <div class="w-header-a">
                  <h3 class="w-title-a text-brand">The Party Rental Station</h3>
                </div>
                <div class="w-body-a">
                  <p class="w-text-a color-text-a">
                    The Party Rentals Station (TPRS) is a Stylish Event Rental
                    Company offering the most unique line of luxury décor items
                    and event solutions.
                  </p>
                </div>
                <div class="w-footer-a">
                  <ul class="list-unstyled">
                    <li class="color-a">
                      <span class="color-text-a">Phone .</span>{" "}
                      <a href="tel:+2349051135384">+234 905 113 5384</a>
                    </li>
                    <li class="color-a">
                      <span class="color-text-a">Email .</span>{" "}
                      <a href="mailto:thepartyrentalstation@gmail.com">
                        thepartyrentalstation@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 section-md-t3">
              <div class="widget-a">
                <div class="w-header-a">
                  <h3 class="w-title-a text-brand">The Company</h3>
                </div>
                <div class="w-body-a">
                  <div class="w-body-a">
                    <ul class="list-unstyled">
                      <li class="item-list-a">
                        <i class="fa fa-angle-right"></i>
                        <Link to="/about"> About</Link>
                      </li>
                      <li class="item-list-a">
                        <i class="fa fa-angle-right"></i>{" "}
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li class="item-list-a">
                        <i class="fa fa-angle-right"></i>{" "}
                        <Link to="/faqs">FAQs</Link>
                      </li>
                      <li class="item-list-a">
                        <i class="fa fa-angle-right"></i>{" "}
                        <Link to="/">Careers</Link>
                      </li>
                      <li class="item-list-a">
                        <i class="fa fa-angle-right"></i>{" "}
                        <Link to="/terms-and-conditions">
                          Terms and Conditions
                        </Link>
                      </li>
                      <li class="item-list-a">
                        <i class="fa fa-angle-right"></i>{" "}
                        <Link to="/terms-and-conditions">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 section-md-t3">
              <div class="widget-a">
                <div class="w-header-a">
                  <h3 class="w-title-a text-brand">Quick Links</h3>
                </div>
                <div class="w-body-a">
                  <ul class="list-unstyled">
                    <li class="item-list-a">
                      <i class="fa fa-angle-right"></i> <Link to="/">Home</Link>
                    </li>
                    <li class="item-list-a">
                      <i class="fa fa-angle-right"></i>
                      <Link to="/about"> About</Link>
                    </li>
                    <li class="item-list-a">
                      <i class="fa fa-angle-right"></i>{" "}
                      <Link to="projects">Projects</Link>
                    </li>
                    <li class="item-list-a">
                      <i class="fa fa-angle-right"></i>{" "}
                      <Link to={`/item/${links[0]}`}>Rentals</Link>
                    </li>
                    <li class="item-list-a">
                      <i class="fa fa-angle-right"></i>{" "}
                      <Link to="/login">Login</Link>
                    </li>
                    <li class="item-list-a">
                      <i class="fa fa-angle-right"></i>{" "}
                      <Link to="/register">Sign Up</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav class="nav-footer">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/about">About</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/item/speciality-furniture">Rentals</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/">Blog</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
              <div class="socials-a">
                <ul class="list-inline">
                  {socials.map((item) => (
                    <li class="list-inline-item">
                      <a href={item.link} target="_blank" rel="noreferral">
                        {item.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="copyright-footer">
                <p class="copyright color-text-a">
                  &copy; Copyright
                  <span class="color-a">TPRS</span> All Rights Reserved.
                </p>
              </div>
              <div class="credits">
                {/* <!--
                      All the links in the footer should remain intact.
                      You can delete the links only if you purchased the pro version.
                      Licensing information: https://bootstrapmade.com/license/
                      Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=EstateAgency
                    -->
                    <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> --> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
