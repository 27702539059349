import styled from "styled-components";
export const Container = styled.div``;

export const Body = styled.div`
  padding: 70px 0;
`;

export const Content = styled.div`
  .h-services {
    text-align: center;
  }
  .p-services {
    text-align: center;
  }
`;
