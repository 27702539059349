import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    z-index:1500;
    position:fixed;
    width:100%;
    top:0;
    bottom:0;
    background:rgba(0,0,0,.6);
    height:100vh;
    .wrapper{
        display:flex;
        justify-content:center;
        align-items:center;
        height:100%;
        .icon-container{
            width: fit-content;
            margin: 0 auto;
            i{
                color:#fff;
                font-size:150px;
                width:fit-content;
                margin:0 auto;
            }
        }
        .text{
            h6,p{
                font-size:25px;
                color:#fff;
                text-align:center;
            }
            p{
                font-size:20px;
            }
        }
    }
    .cancel{
        margin-top:130px;
        padding-right:70px;
        float:right;
        @media only screen and (max-width:450px){
            margin-top:30px;
            padding-right:30px;
        }
        i{
            font-size:50px;
            font-weight:300;
            color:#fff;
            cursor:pointer;
            @media only screen and (max-width:450px){
                font-size:30px;
            }
        }
    }

`;
const SuccessModal = ({status}) => {

    const cancel = () =>{
        localStorage.removeItem("cart-items")
        window.location.reload()
    }
    return (
        <Container style={{left: status ? "0": "-100%"}}>
            <div className="cancel"><i className="fa fa-times" onClick={() => (cancel())}></i></div>
            <div className="wrapper">
                <div>
                    <div className="icon-container">
                        <i className="fa fa-check"></i>
                    </div>

                    <div className="text">
                        <h6>Order has been received successfully!</h6>
                        <p>Check your user dashboard for update</p>
                    </div>
                </div>
                
            </div>
        </Container>
    );
}

export default SuccessModal;
