import styled from "styled-components";
export const Container = styled.div`
  .my-prev,
  .my-next {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 600;
    color: #000;

    :hover {
      color: #8b008b;
    }
  }

  .my-prev.text-fade,
  .my-next.text-fade {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Body = styled.div`
  padding: 70px 0;
`;

export const Test = styled.div`
  // color:red;
`;

export const Content = styled.div`
  .h-services {
    text-align: center;
  }
  .p-services {
    text-align: center;
  }
`;
