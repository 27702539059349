import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Wrapper } from "./style";

export default function Projects() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const images = [
    "img/new-batch1-1.jpg",
    "img/new-batch1-2.jpg",
    "img/new-batch1-3.jpg",
    "img/new-batch1-4.jpg",
    "img/new-batch1-5.jpg",
    "img/new-batch1-6.jpg",
    "img/new-batch1-8.jpg",
    "img/new-batch1-9.jpg",
    "img/new-batch1-21.jpg",
    "img/new-batch1-7.jpg",
    "img/new-batch1-10.jpg",
    "img/new-batch1-11.jpg",
    "img/new-batch1-12.jpg",
    "img/new-batch1-13.jpg",
    "img/new-batch1-14.jpg",
    "img/new-batch1-15.jpg",
    "img/new-batch1-16.jpg",
    "img/new-batch1-17.jpg",
    "img/new-batch1-18.jpg",
    "img/new-batch1-18.jpg",
    "img/new-batch1-19.jpg",
    "img/new-batch1-20.jpg",
    "img/gallery/gallery3.jpg",
    "img/gallery/gallery5.jpg",
    "img/gallery/gallery14.jpg",
    "img/gallery/gallery11.jpg",
    "img/gallery/gallery16.jpg",
    "img/gallery/gallery17.jpg",
    "img/gallery/gallery18.jpg",
    "img/gallery/gallery19.jpg",
  ];
  return (
    <Wrapper>
      <Header />
      <section className="intro-single">
        <div className="container">
          <h1>Project Gallery</h1>
          <p className="mt-3" style={{ fontSize: "17px" }}>
            Discover our portfolio of completed projects, each crafted with
            precision and dedication. This gallery highlights our commitment to
            excellence and innovation across various endeavors. Explore our work
            and see how we bring ideas to life, one project at a time.
          </p>
        </div>
      </section>

      <section className="gallery p-0">
        <div class="container-fluid">
          <div class="row">
            {images.map((item) => (
              <div class="col-md-6 col-lg-4 gallery-item " key={item}>
                <div class="cover cover-up"></div>
                <img src={item} alt="TPRS project" />

                <div class="cover cover-down"></div>
              </div>
            ))}
            {/* <div class="col-md-6 col-lg-4 gallery-item gallery-video-item">
              <div class="cover cover-up"></div>
              <img src="img/new-batch1-20.jpg" alt="TPRS project" />

              <div class="cover cover-down"></div>
              <div class="video-play-wrapper">
                <i
                  class="fa fa-play fa-solid fa-2x"
                  style={{ color: "#8b008b" }}
                ></i>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="my-5 pt-md-4">
        <div className="container">
          <h2 className="text-center">Let’s Bring Your Event to Life!</h2>
          <p className="text-center">
            Planning a special event? Partner with us to elevate your occasion
            with our wide selection of rental items. From elegant chairs and
            stylish canopies to spacious tents and more, we have everything you
            need to create a memorable setting. Let us handle the details so you
            can focus on enjoying the moment. Reach out today, and let’s make
            your event truly unforgettable!
          </p>
          <div
            className="d-flex flex-wrap align-items-center justify-content-center mt-5"
            style={{ gap: "15px" }}
          >
            <button
              type="button"
              class="whatsapp-dm-btn"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=%2B2349051135384&text=Hello%2C+I+would+like+to+rent",
                  "_blank"
                )
              }
            >
              Send Us a DM on Whatsapp 1
            </button>

            <button
              type="button"
              class="whatsapp-dm-btn whatsapp-dm-btn-2"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=%2B2348186212894&text=Hello%2C+I+would+like+to+rent",
                  "_blank"
                )
              }
            >
              Send Us a DM on Whatsapp 2
            </button>

            <button
              type="button"
              class="whatsapp-dm-btn whatsapp-dm-btn-3"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=%2B2348186212894&text=Hello%2C+I+would+like+to+rent",
                  "_blank"
                )
              }
            >
              Send Us a DM on Whatsapp 3
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </Wrapper>
  );
}
