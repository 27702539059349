import React, { useEffect } from "react";
import * as UI from "./style";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { links } from "../../utils/navLinks";
import PdfViewer from "../../components/PdfViewer";
import PDFViewer from "../../components/PdfViewer";

export default function Product(props) {
  const { product } = props.match.params || {};
  const categoryName = product.replaceAll("-", " ");
  const pdfFile = `/catalogs/tprs-brochure-${product}.pdf`;

  useEffect(() => {
    window.scroll(0, 0);
  }, [product]);
  return (
    <UI.Container>
      <Header />
      <section class="intro-single pb-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box pl-4">
                {props.match.params.product && (
                  <h1
                    class="title-single"
                    style={{
                      textTransform: "capitalize",
                      maxWidth: "570px",
                    }}
                  >
                    {categoryName}
                  </h1>
                )}
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {categoryName}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section class="property-grid grid">
        <div class="container">
          <div class="row">
            <PDFViewer file={pdfFile} />
            {/* <PdfViewer pdfFile={pdfFile} /> */}
            {/* <iframe
              src={`${pdfFile}`}
              src={`https://docs.google.com/gview?url=${encodeURIComponent(
                pdfFile
              )}&embedded=true`}
              width="100%"
              height="700px"
            /> */}
          </div>
        </div>
      </section>

      <section className="py-5 mt-3">
        <h2 className="text-center">Would You Like to Place an Order?</h2>

        <div
          className="d-flex flex-wrap align-items-center justify-content-center mt-4"
          style={{ gap: "15px" }}
        >
          <button
            type="button"
            class="whatsapp-dm-btn"
            onClick={() =>
              window.open(
                `https://api.whatsapp.com/send/?phone=%2B2349051135384&text=Hello%2C+I+would+like+to+make+a+booking+for+${categoryName}`,
                "_blank"
              )
            }
          >
            Book Product Now
          </button>

          <button
            type="button"
            class="whatsapp-dm-btn whatsapp-dm-btn-2"
            onClick={() =>
              window.open(
                `https://api.whatsapp.com/send/?phone=%2B2349051135384&text=Hello%2C+I+would+like+to+make+a+booking+for+${categoryName}`,
                "_blank"
              )
            }
          >
            Book Product Now
          </button>
        </div>
      </section>

      {links.includes(product) && (
        <div class="container pb-5">
          <h6
            className="text-center"
            style={{
              fontSize: "19px",
              fontWeight: 500,
            }}
          >
            You can download the brochure for this category{" "}
            <a
              download
              href={pdfFile}
              style={{
                fontSize: "17px",
                textDecoration: "underline",
                color: "#8b008b",
                cursor: "pointer",
              }}
            >
              here
            </a>
          </h6>
        </div>
      )}

      <Footer />
    </UI.Container>
  );
}
