import React, { Component } from "react";
import * as UI from "./style";
import Box1 from "../../components/Box-1";
import { Link } from "react-router-dom";
import AdminDashboardEssentials from "../../components/Admin-Dashboard-Essentials";
import axios from "axios";

export default class DashboardHome extends Component {
  state = {};

  componentDidMount() {
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc`
      )
      .then((res) => {
        // console.log(res.data.doc)
        this.setState({
          allOrder: res.data.doc.length,
        });
      });
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc&status=accepted`
      )
      .then((res) => {
        // console.log(res.data.doc)
        this.setState({
          acceptedOrder: res.data.doc.length,
        });
      });
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc&status=rejected`
      )
      .then((res) => {
        // console.log(res.data.doc)
        this.setState({
          rejectedOrder: res.data.doc.length,
        });
      });
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc&status=cancelled`
      )
      .then((res) => {
        // console.log(res.data.doc)
        this.setState({
          cancelledOrder: res.data.doc.length,
        });
      });
    axios
      .get(
        `https://sheltered-cliffs-11423.herokuapp.com/api/order/get?skip=0&order=desc&status=pending`
      )
      .then((res) => {
        // console.log(res.data.doc)
        this.setState({
          pendingOrder: res.data.doc.length,
        });
      });
  }
  render() {
    return (
      <UI.Container>
        <AdminDashboardEssentials prop={this.props}>
          <div className="container">
            <h2>Dashboard Home</h2>
            <div className="row">
              <div className="col-lg-3">
                <Link to="/admin/orders/all">
                  <Box1
                    heading="All Orders"
                    value={this.state.allOrder}
                    icon="truck"
                  />
                </Link>
              </div>

              <div className="col-lg-3">
                <Link to="/admin/orders/accepted">
                  <Box1
                    heading="Accepted Orders"
                    value={this.state.acceptedOrder}
                    icon="check"
                  />
                </Link>
              </div>
              <div className="col-lg-3">
                <Link to="/admin/orders/rejected">
                  <Box1
                    heading="Rejected Orders"
                    value={this.state.rejectedOrder}
                    icon="ban"
                  />
                </Link>
              </div>

              <div className="col-lg-3">
                <Link to="/admin/orders/cancelled">
                  <Box1
                    heading="Cancelled Orders"
                    value={this.state.cancelledOrder}
                    icon="close"
                  />
                </Link>
              </div>

              <div className="col-lg-3">
                <Link to="/admin/orders/pending">
                  <Box1
                    heading="Pending Orders"
                    value={this.state.pendingOrder}
                    icon="hourglass-start"
                  />
                </Link>
              </div>
            </div>
          </div>
        </AdminDashboardEssentials>
      </UI.Container>
    );
  }
}

// image1
// image2
// tag
// title
// desc
// author
