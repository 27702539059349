export const links = [
  "specialty-chairs",
  "tables",
  "cocktail-furniture",
  "lounge-sets-and-sofas",
  "vases-and-centerpieces",
  "cake-tables",
  "lighting-and-special-effects",
  "table-linens",
  "decor-props",
  "tents,-marquee-and-accessories",
  "kiddies'-collections",
];
